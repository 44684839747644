import { attr, hasMany, belongsTo } from 'spraypaint/dist/spraypaint';
import ApplicationRecord from '@/models/ApplicationRecord';

const Invoice = ApplicationRecord.extend({
  static: {
    jsonapiType: 'invoices',
  },
  attrs: {
    creatorId: attr({ persist: false }),

    internalNote: attr(),
    createdAt: attr({ persist: false }),

    creator: belongsTo(),
    lineItems: hasMany()
  }
});

export default Invoice
