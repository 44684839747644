<template>
  <span>
    {{ formattedAmount }}
  </span>
</template>

<script>
export default {
  props: {
    amount: {
      type: Number,
      required: true
    }
  },
  computed: {
    formattedAmount() {
      return new Intl.NumberFormat('de-DE', { style: 'currency', currency: 'EUR' })
        .format(this.amount);
    }
  }
}
</script>
