<template>
  <select v-if="customers.length > 1"
          v-model="customer"
          class="mt-2 px-3 w-full py-2 bg-white border border-neutral-300 rounded">
    <option v-for="customer in customers"
            :value="customer"
            :key="customer.id">
      {{ customer.displayTitle() }}
    </option>
  </select>
  <div class="mt-2 px-3 w-full py-2 border bg-neutral-100 text-neutral-500 border-neutral-300 rounded" v-else>
    {{ customer.displayTitle() }}
  </div>
</template>

<script>
export default {
  computed: {
    customers() { return this.$store.state.lineItem.customers; },
    customer: {
      get() { return this.$store.state.lineItem.lineItemSelectedCustomer; },
      set(customer) { this.$store.dispatch('lineItem/updateCustomer', customer) }
    }
  }
}
</script>
