<template>
  <div :class="active ? '' : ''"
        class="bg-white shadow pt-4 pb-5 px-6 flex rounded flex-col pt-8">
    <slot></slot>
  </div>
</template>

<script>
export default {
  name: 'SecondaryContainer',
  props: {
    active: {
      type: Boolean,
      default: false
    }
  }
}
</script>
