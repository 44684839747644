<template>
  <span>
    <Currency class="font-semibold" :amount="amount" />
  </span>
</template>

<script>
import Currency from '@/components/Currency';

export default {
  components: {
    Currency
  },
  props: ['amount']
}
</script>
