import User from '@/models/User';

export default {
  state: {
    token: localStorage.getItem('jwt'),
    currentUser: null
  },
  mutations: {
    setCurrentUser(state, user) { state.currentUser = user },
    dropToken(state) {
      localStorage.removeItem('jwt');
      state.token = null;
    }
  },
  actions: {
    async fetchCurrentUser({ state, commit }) {
      const user = await User.find('me');
      const data = user.data;
      commit('setCurrentUser', data);
      // Sentry.setUser({
      //   id: data.id,
      //   email: data.email,
      //   username: [data.firstname, data.lastname].join(' ')
      // });
      // Sentry.setTag('group', data.group);

      return user;
    }
  },
  getters: {
    hasToken: state => Boolean(state.token),
    isAuthenticated: state => Boolean(state.currentUser),
    token: state => state.token,
    currentUser: state => state.currentUser
  }
}
