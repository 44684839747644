import { attr } from 'spraypaint/dist/spraypaint';
import ApplicationRecord from '@/models/ApplicationRecord';

const User = ApplicationRecord.extend({
  static: {
    jsonapiType: 'users',
  },
  attrs: {
    email: attr({ persist: false }),
    isActive: attr({ persist: false }),
    firstname: attr({ persist: false }),
    lastname: attr({ persist: false }),
    roles: attr({ persist: false }),
  },
  methods: {
    isAdmin() {
      return this.roles.includes('admin');
    },
    isProductManager() {
      return this.roles.includes('pm') || this.roles.includes('teamlead') || this.isAdmin();
    },
    isBackoffice() {
      return this.roles.includes('backoffice') || this.isAdmin();
    },
    isTeamLead() {
      return this.roles.includes('teamlead') || this.isAdmin();
    },
    displayName() {
      return `${this.firstname} ${this.lastname}`;
    }
  }
});

export default User
