<template>
  <AppContainer>
    <PrimaryContainer :active="Boolean(!activeLineItems.length)">
      <div class="pb-3">
        <SectionHeading>Neue Rechnungen zusammenstellen</SectionHeading>
      </div>
      <div v-if="!customers.length">
        <span class="py-1">keine offenen Rechnungsposition</span>
      </div>
      <ScrollContainer v-else>
        <table class="bg-gray-50 border-collapse w-full table-auto whitespace-nowrap">
          <thead>
            <tr class="divide-x divide-gray-300 leading-7">
              <th class="sticky top-0 bg-gray-200 text-gray-500 font-medium p-1 text-left w-20">Nr.</th>
              <th class="sticky top-0 bg-gray-200 text-gray-500 font-medium p-1 text-left">Kunde</th>
            </tr>
          </thead>
          <tbody>
            <template v-for="(customer, idx) in customers">
              <tr :key="customer.id"
                  class="hover:bg-gray-200 text-gray-600 hover:text-gray-800 cursor-pointer"
                  :class="[idx % 2 === 1 ? 'bg-gray-100 text-gray-800' : '']"
                  @click="fetchLineItemsForCustomer(customer)">

                <td class="p-1">
                  {{ customer.number }}
                </td>
                <td class="p-1">
                  {{ customer.name }}
                </td>
              </tr>
              <tr v-if="customer === activeCustomer"
                  :key="customer.id + '_lineItems'">
                <td colspan="2" class="py-0.5 px-1">
                  <LineItemDetails :lineItems="lineItemsForCustomer"
                                   :activeLineItems="activeLineItems"
                                   @line-item-selected="toggleLineItem($event)" />
                </td>
              </tr>
            </template>
          </tbody>
        </table>
      </ScrollContainer>
    </PrimaryContainer>
    <SecondaryContainer :active="Boolean(activeLineItems.length)">
      <div class="pb-4 flex items-center">
        <SectionHeading>Rechnung zusammenstellen</SectionHeading>
        <template v-if="activeLineItems.length">

          <MainActionButton @click="saveInvoice">
            erstellen
          </MainActionButton>

          <SecondaryActionButton @click="deleteLineItems">
            löschen
          </SecondaryActionButton>

          <SecondaryActionButton @click="deactivateLineItems">
            abbrechen
          </SecondaryActionButton>

        </template>
      </div>
      <div v-if="successNote">
        <span class="text-green-500 py-1">{{ successNote }}</span>
      </div>
      <div v-if="!activeCustomer.id">
        <span class="py-1">Kunden für Rechnung auswählen</span>
      </div>
      <div v-else-if="!activeLineItems.length">
        <span class="py-1">Rechnungsposition für Rechnung auswählen</span>
      </div>
      <ScrollContainer v-else class="text-gray-600">
        <div class="bg-gray-50">
          <DataRow>
            <textarea placeholder="Hinweis für Buchhaltung"
                      v-model="invoiceInternalNote"
                      class="w-full px-1 border border-gray-300">
            </textarea>
          </DataRow>
        </div>
        <div>
          <div class="pb-3 pt-8">
            <h2 class="text-base font-semibold">
              enthaltene Rechnungspositionen ({{ activeLineItems.length }})
            </h2>
          </div>
          <div class="border-t border-b border-gray-300 bg-gray-50 overflow-scroll">
            <table class="border-collapse w-full table-auto whitespace-nowrap">
              <thead>
                <tr class="divide-x divide-gray-300 leading-7">
                  <th class="sticky top-0 bg-gray-200 text-gray-500 font-medium p-1 text-left">Position</th>
                  <th class="sticky top-0 bg-gray-200 text-gray-500 font-medium p-1 text-left">angefallen</th>
                  <th class="sticky top-0 bg-gray-200 text-gray-500 font-medium p-1 text-left">abgerechnet</th>
                  <th class="sticky top-0 bg-gray-200 text-gray-500 font-medium p-1 text-left">Betrag</th>
                  <th class="sticky top-0 bg-gray-200 text-gray-500 font-medium p-1 text-left">WV</th>
                </tr>
              </thead>
              <tbody>
                <template v-for="(lineItem, idx) in activeLineItems">
                  <tr :key="lineItem.id + 'data'"
                      class="text-gray-600 hover:text-gray-800"
                      :class="[idx % 2 === 1 ? 'bg-gray-100 text-gray-800' : '']">

                    <td class="p-1">
                      {{ lineItem.title }}
                    </td>
                    <td class="p-1">
                      {{ prettyTime(lineItem.timeSpentInSeconds) }}
                    </td>
                    <td class="p-1">
                      {{ prettyTime(lineItem.billedTimeInSeconds) }}
                    </td>
                    <td class="p-1">
                      <Currency :amount="lineItem.amount" />
                    </td>
                    <td class="p-1">
                      {{ lineItem.serviceContractId ? 'ja' : 'nein' }}
                    </td>
                  </tr>
                </template>
              </tbody>
              <tfoot>
                <tr class="text-gray-600 hover:text-gray-800 border-2 font-bold">
                  <td class="p-1">
                    &nbsp;
                  </td>
                  <td class="p-1">
                    {{ prettyTime(activeLineItems.reduce((sum, l) => (sum += l.timeSpentInSeconds, sum), 0)) }}
                  </td>
                  <td class="p-1">
                    {{ prettyTime(activeLineItems.reduce((sum, l) => (sum += l.billedTimeInSeconds, sum), 0)) }}
                  </td>
                  <td class="p-1">
                    <Currency :amount="activeLineItemsAmountSum" />
                  </td>
                  <td class="p-1">
                    &nbsp;
                  </td>
                </tr>
              </tfoot>
            </table>
          </div>
        </div>
      </ScrollContainer>
    </SecondaryContainer>
  </AppContainer>
</template>

<script>
// notizen f. lines view
  // keine lines ausgeben, die
    // zu einem projekt gehören, dass invoices braucht
    // und keine invoice id haben
  // problem: pm sollte die doch sehen und löschen können...
    // neuer view f. pm oder query anpassen?
import timeHelpers from '@/lib/time_helpers';

import Customer from '@/models/Customer';
import LineItem from '@/models/LineItem';
import Invoice from '@/models/Invoice';

import AppContainer from '@/components/AppContainer';
import PrimaryContainer from '@/components/PrimaryContainer';
import SecondaryContainer from '@/components/SecondaryContainer';
import SectionHeading from '@/components/SectionHeading';
import ScrollContainer from '@/components/ScrollContainer';
import MainActionButton from '@/components/MainActionButton';
import SecondaryActionButton from '@/components/SecondaryActionButton';
import LineItemDetails from '@/components/LineItemDetails';
import DataRow from '@/components/DataRow';
import Currency from '@/components/Currency';

export default {
  name: 'Invoices',
  components: {
    AppContainer,
    PrimaryContainer,
    SecondaryContainer,
    SectionHeading,
    ScrollContainer,
    LineItemDetails,
    MainActionButton,
    SecondaryActionButton,
    DataRow,
    Currency
  },
  data() {
    return {
      customers: [],
      activeCustomer: {},
      lineItemsForCustomer: [],
      activeLineItems: [],
      invoiceInternalNote: '',
      successNote: '',
    };
  },
  async created() {
    this.fetchCustomers();
  },
  computed: {
    activeLineItemsAmountSum() {
      return this.activeLineItems.reduce((sum, l) => (sum += l.amount, sum), 0);
    }
  },
  methods: {
    prettyTime: timeHelpers.prettyTime,
    async fetchCustomers() {
      const customers = await Customer
        .where({ requiresInvoice: true })
        .order('name')
        .all();
      this.customers = customers.data;
    },
    async fetchLineItemsForCustomer(activeCustomer, { refresh } = {}) {
      this.successNote = '';
      this.deactivateLineItems();

      if (!refresh && this.activeCustomer.id === activeCustomer.id) {
        this.deactivateCustomer();
      } else {
        this.lineItemsForCustomer = (await LineItem
          .where({ customer_id: activeCustomer.id })
          .where({ requiresInvoice: true })
          .all()).data;

        this.activeCustomer = activeCustomer;
      }

    },
    toggleLineItem(lineItem) {
      this.successNote = '';

      if (!this.activeLineItems.includes(lineItem)) {
        this.activeLineItems = [...this.activeLineItems, lineItem];
      } else {
        this.activeLineItems = this.activeLineItems.filter(l => l.id !== lineItem.id);
      }
    },
    async deleteLineItems() {
      // move into store
      await Promise.all(this.activeLineItems.map(item => item.destroy()));
      await this.refreshLineItems();
      this.successNote = 'Rechnungsposition wurde gelöscht!';
    },
    deactivateLineItems() {
      this.activeLineItems = [];
      this.lineItemInternalNote = '';
    },
    deactivateCustomer() {
      this.activeCustomer = {};
    },
    async refreshLineItems() {
      this.deactivateLineItems();
      await this.fetchLineItemsForCustomer(this.activeCustomer, { refresh: true });
      if (!this.lineItemsForCustomer.length) {
        this.deactivateCustomer();
        this.fetchCustomers();
      }
    },
    async saveInvoice() {
      const invoice = new Invoice({
        internalNote: this.invoiceInternalNote,
        creator: this.$store.getters.currentUser,
        lineItems: this.activeLineItems,
      });

      await invoice.save({ with: ['creator.id', 'lineItems.id'] });
      await this.refreshLineItems();
      this.successNote = 'Rechnung wurde erfolgreich erstellt.';
    }
  }
}
</script>
