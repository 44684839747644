<template>
  <div class="text-sm h-full pb-10">
    <slot></slot>
    <Spinner v-if="isLoading" />
  </div>
</template>

<script>
import Spinner from '@/components/Spinner';

export default {
  components: {
    Spinner,
  },
  computed: {
    isLoading() { return this.$store.getters.isLoading }
  }
}
</script>
