<template>
  <div id="app" class="min-h-screen bg-neutral-100">
    <template v-if="$store.getters.isAuthenticated">
      <HeaderBar />
    </template>
    <div id="main" class="px-8">
      <router-view />
    </div>
    <template v-if="$store.getters.isAuthenticated">
      <FooterBar />
    </template>
  </div>
</template>

<style>
  @tailwind base;
  @tailwind components;
  @tailwind utilities;

  @layer base {

  }

  #main {
    /* height: calc(100vh - 2rem - 2.5rem); */
    /* overflow: hidden; */
  }
</style>

<script>
import HeaderBar from '@/components/HeaderBar.vue';
import FooterBar from '@/components/FooterBar.vue';

export default {
  components: {
    HeaderBar,
    FooterBar
  }
}
</script>
