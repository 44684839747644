<template>
  <a :href="href" target="_blank" class="text-primary hover:underline">
    {{ jiraKey }}
  </a>
</template>

<script>
export default {
  props: {
    jiraKey: {
      type: String,
      required: true
    }
  },
  computed: {
    href() {
      return `https://louis-internet.atlassian.net/browse/${this.jiraKey}`;
    }
  }
}
</script>
