import { attr, hasMany, belongsTo } from 'spraypaint/dist/spraypaint';
import ApplicationRecord from '@/models/ApplicationRecord';

const Issue = ApplicationRecord.extend({
  static: {
    jsonapiType: 'issues',
  },
  attrs: {
    jiraId: attr({ persist: false }),
    parentId: attr({ persist: false }),
    ancestorIds: attr({ persist: false }),
    level: attr({ persist: false}),
    jiraKey: attr({ persist: false }),
    summary: attr({ persist: false }),
    billable: attr({ persist: false }),
    billableType: attr({ persist: false }),

    timeSpentInSeconds: attr({ persist: false }),
    timeSpentInSecondsRoundedToFullQuarters: attr({ persist: false }),
    offerAmountInSeconds: attr({ persist: false }),
    originalEstimateInSeconds: attr({ persist: false }),
    remainingEstimateInSeconds: attr({ persist: false }),
    forecastFinalTimeInSeconds: attr({ persist: false }),
    billableTimeSuggestionInSeconds: attr({ persist: false}),
    serviceContract: attr({ persist: false}),
    billedTimeInSeconds: attr({ persist: false}),
    openTimeInSeconds: attr({ persist: false}),

    resolvedAt: attr({ persist: false }),

    project: belongsTo(),
    worklogs: hasMany()
  },
  methods: {
    useOffer() {
      return this.billableType === 'nach Angebot';
    },
    prettyResolvedAt() {
      if (this.resolvedAt) {
        return (new Date(this.resolvedAt)).toLocaleString('de-de');
      } else {
        return '';
      }
    },
    prettyDeliveryDate() {
      if (this.resolvedAt) {
        return (new Date(this.resolvedAt)).toLocaleDateString('de-de');
      } else {
        return (new Date()).toLocaleDateString('de-de');
      }
    }
  }
});

export default Issue
