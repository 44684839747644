<template>
  <div>
    <div class="border-t border-b border-gray-300 bg-gray-50 overflow-scroll">
      <table class="border-collapse w-full table-auto whitespace-nowrap">
        <thead>
          <tr class="divide-x divide-gray-300 leading-7">
            <th v-if="selectable"
                class="sticky top-0 bg-gray-200 text-gray-500 font-medium p-1 text-left w-0">
            </th>
            <th class="sticky top-0 bg-gray-200 text-gray-500 font-medium p-1 text-left w-20">Aufgabe</th>
            <th v-if="!hideUser"
                class="sticky top-0 bg-gray-200 text-gray-500 font-medium p-1 text-left">Benutzer</th>
            <th class="sticky top-0 bg-gray-200 text-gray-500 font-medium p-1 text-left">Aufwand</th>
            <th class="sticky top-0 bg-gray-200 text-gray-500 font-medium p-1 text-left">Datum</th>
            <th class="sticky top-0 bg-gray-200 text-gray-500 font-medium p-1 text-left">Kommentar</th>
          </tr>
        </thead>
        <tbody>
          <template v-for="(worklog, idx) in worklogs">
            <tr v-if="username === worklog.jiraUser.displayName"
                :key="worklog.id"
                class="text-gray-600 hover:text-gray-800"
                :class="[idx % 2 === 1 ? 'bg-gray-100 text-gray-800' : '']">
              <td v-if="selectable"
                  class="p-1">
                <input type="checkbox"
                       class="relative top-0.5"
                       :checked="checkActiveState(worklog)"
                       @change="toggleWorklog(worklog)"/>
              </td>
              <td class="p-1">
                <IssueLink :jiraKey="worklog.jiraKey" />
              </td>
              <td v-if="!hideUser"
                  class="p-1">
                {{ worklog.jiraUser.displayName }}
              </td>
              <td class="p-1">
                {{ prettyTime(worklog.timeSpentInSeconds) }}
              </td>
              <td class="p-1">
                {{ worklog.prettyStartedAt() }}
              </td>
              <td class="p-1">
                {{ worklog.comment }}
              </td>
            </tr>
          </template>
        </tbody>
      </table>
    </div>
  </div>
</template>

<script>
import timeHelpers from '@/lib/time_helpers';

import IssueLink from '@/components/IssueLink';

export default {
  components: {
    IssueLink
  },
  props: ['worklogs', 'selectable', 'username', 'hideUser'],
  computed: {
    selectedWorklogs() { return this.$store.state.lineItem.lineItemSelectedWorklogs; },
  },
  methods: {
    prettyTime: timeHelpers.prettyTime,
    checkActiveState(worklog) {
      return this.selectedWorklogs.some(w => w.id === worklog.id);
    },
    toggleWorklog(worklog) {
      this.$store.dispatch('lineItem/toggleWorklog', worklog);
    }
  }
}
</script>
