<template>
  <table class="w-full">
    <tbody>
      <template v-for="(issue, idx) in rootIssuesForProject">
        <tr class="text-gray-800"
            :class="[idx % 2 === 0 ? 'bg-gray-50' : 'bg-gray-50' ]"
            :key="issue.id">
          <td class="w-7 min-w-min pl-2 p-1 align-top">
            <input type="checkbox"
                   :id="issue.jiraKey"
                   :checked="checkActiveState(issue)"
                   @change="$emit('issue-selected', issue)"/>
          </td>
          <td class="w-32 min-w-min p-1 align-top">
            <IssueLink :jiraKey="issue.jiraKey" />
          </td>
          <td class="p-1 align-top whitespace-normal">
            <label class="cursor-pointer" :for="issue.jiraKey">{{ issue.summary }}</label>
          </td>
        </tr>
        <tr :key="issue.id + 'subtasks'">
          <td colspan="4" class="p-0 pl-3">
            <SubTasks :issuesForProject="issuesForProject"
                      :checkActiveState="checkActiveState"
                      :currentIssue="issue"
                      :level="1"
                      @issue-selected="$emit('issue-selected', $event)"/>
          </td>
        </tr>
      </template>
    </tbody>
  </table>
</template>

<script>
import IssueLink from '@/components/IssueLink';
import SubTasks from '@/components/SubTasks';

export default {
  name: 'ProjectDetails',
  components: {
    IssueLink,
    SubTasks,
  },
  props: {
    issuesForProject: {
      type: Array,
      required: true
    },
      activeIssues: {
      type: Array,
      required: true
    }
  },
  computed: {
    rootIssuesForProject() {
      return this.issuesForProject.filter(i => !i.parentId);
    }
  },
  methods: {
    checkActiveState(issue) {
      return this.activeIssues.includes(issue);
    }
  }
}
</script>
