import { SpraypaintBase, MiddlewareStack } from 'spraypaint/dist/spraypaint';
import store from '@/store';

const middlewareStack = new MiddlewareStack();

middlewareStack.beforeFilters.push((url, options) => {
  // DELETE requests do not trigger afterFilters for some unknown reason...
  // https://github.com/graphiti-api/spraypaint.js/blob/69b8dc34aa8b1a55869c1e1dc3adacaab583b542/src/request.ts#L124

  if (options.method !== 'DELETE') store.dispatch('activateLoadingState');
});

middlewareStack.afterFilters.push((response, json) => {
  store.dispatch('deactivateLoadingState');
});

const ApplicationRecord = SpraypaintBase.extend({
  static: {
    baseUrl: '',
    apiNamespace: '/api/v1',
    generateAuthHeader(token) {
      return 'Bearer ' + token
    }
  }
});
ApplicationRecord.middlewareStack = middlewareStack;

export default ApplicationRecord
