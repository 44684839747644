import Vue from 'vue';
import Vuex from 'vuex';
import currentUser from '@/store/currentUser';
import lineItem from '@/store/lineItem';

Vue.use(Vuex);

export default new Vuex.Store({
  strict: false, //process.env.NODE_ENV !== 'production', // only mutate state in mutation handlers
  state: {
    loading: 0,
  },
  mutations: {
    activateLoadingState(state) { state.loading += 1; },
    deactivateLoadingState(state) { state.loading -= 1; }
  },
  actions: {
    activateLoadingState(context) { context.commit('activateLoadingState') },
    deactivateLoadingState(context) { context.commit('deactivateLoadingState') },
  },
  getters: {
    isLoading(state) { return Boolean(state.loading); },
  },
  modules: {
    currentUser,
    lineItem
  }
})
