<template>
  <AppContainer>
    <PrimaryContainer :active="Boolean(!activeIssues.length)">
      <div class="pb-3">
        <SectionHeading>Abgeschlossene Vorgänge</SectionHeading>
      </div>
      <ScrollContainer class="border border-gray-300">
        <table class="issues-done bg-gray-50 border-collapse w-full table-auto whitespace-nowrap">
          <thead>
            <tr class="divide-x divide-gray-300 leading-7">
              <th class="sticky top-0 bg-neutral-200 font-medium px-2 text-left w-20">ID</th>
              <th class="sticky top-0 bg-neutral-200 font-medium px-2 text-left">Name</th>
            </tr>
          </thead>
          <tbody>
            <template v-for="(project, idx) in projects">
              <tr :key="project.id"
                  class="hover:bg-gray-200 cursor-pointer border border-x-0"
                  :class="[idx % 2 === 1 ? 'bg-gray-100' : '']"
                  @click="fetchIssuesForProject(project)">

                <td class="px-2 py-1">
                  {{ project.jiraKey }}
                </td>
                <td class="px-2 py-1">
                  {{ project.name }}
                </td>
              </tr>
              <tr v-if="project === activeProject"
                  :key="project.id + '_issues'">
                <td colspan="2" class="p-0">
                  <ProjectDetails :issuesForProject="allIssuesForProject"
                                  :activeIssues="activeIssues"
                                  @issue-selected="activateIssue($event)" />
                </td>
              </tr>
            </template>
          </tbody>
        </table>
      </ScrollContainer>
    </PrimaryContainer>
    <SecondaryContainer :active="Boolean(activeIssues.length)">
      <div class="pb-4 flex items-center">
        <SectionHeading>Rechnungsposition erstellen</SectionHeading>
        <template v-if="activeIssues.length">

          <MainActionButton @click="saveLineItem" :disabled="hasLineItemErrors">
            erstellen
          </MainActionButton>

          <SecondaryActionButton @click="deactivateIssues">
            abbrechen
          </SecondaryActionButton>

        </template>
      </div>
      <div v-if="successNote">
        <span class="text-green-500 py-1">{{ successNote }}</span>
      </div>
      <div v-if="!activeProject.id">
        <span class="py-1">Projekt zur Abrechnung auswählen</span>
      </div>
      <div v-else-if="!activeIssues.length">
        <span class="py-1">Ticket zur Abrechnung auswählen</span>
      </div>
      <ScrollContainer v-else class="">

        <LineItemForm :editable="$store.getters.currentUser.isProductManager()" />

        <IssueData :issues="activeIssues" />

        <WorklogStats :worklogs="selectedWorklogs"
                      :activeProject="activeProject"
                      :issuesTimeSpent="$store.getters['lineItem/timeSpentInSeconds']" />

      </ScrollContainer>
    </SecondaryContainer>
  </AppContainer>
</template>

<style>
</style>

<script>
import Project from '@/models/Project';
import Issue from '@/models/Issue';
import Worklog from '@/models/Worklog';
import Customer from '@/models/Customer';

import AppContainer from '@/components/AppContainer';
import ProjectDetails from '@/components/ProjectDetails';
import LineItemForm from '@/components/LineItemForm';
import IssueData from '@/components/IssueData';
import SectionHeading from '@/components/SectionHeading';
import WorklogStats from '@/components/WorklogStats';
import ScrollContainer from '@/components/ScrollContainer';
import MainActionButton from '@/components/MainActionButton';
import SecondaryActionButton from '@/components/SecondaryActionButton';
import PrimaryContainer from '@/components/PrimaryContainer';
import SecondaryContainer from '@/components/SecondaryContainer';

// import timeHelpers from '@/lib/time_helpers';

export default {
  name: 'IssuesDone',
  components: {
    AppContainer,
    ProjectDetails,
    WorklogStats,
    LineItemForm,
    IssueData,
    SectionHeading,
    MainActionButton,
    SecondaryActionButton,
    ScrollContainer,
    PrimaryContainer,
    SecondaryContainer
  },
  data() {
    return {
      projects: [],
      activeProject: {},
      issuesForProject: [],
      successNote: ''
    };
  },
  async created() {
    this.$store.dispatch('lineItem/resetLineItem');
    this.fetchProjects();
  },
  computed: {
    lineItem() { return this.$store.state.lineItem.lineItem; },
    activeIssues() { return this.$store.getters['lineItem/sortedIssues']; },
    availableWorklogs() { return this.$store.getters['lineItem/unbilledWorklogs']; },
    selectedWorklogs() { return this.$store.state.lineItem.lineItemSelectedWorklogs; },
    rootIssue() { return this.$store.getters['lineItem/rootIssue']; },
    lastResolvedIssue() { return this.$store.getters['lineItem/lastResolvedIssue']; },
    activeCustomers () { return this.$store.state.lineItem.customers; },

    issuesById() {
      return this.issuesForProject.reduce((mem, i) => {
        mem[i.id] = i;
        return mem;
      }, {});
    },
    allIssuesForProject() {
      return Object
        .values(this.issuesById)
        .sort((a,b) => (+b.jiraKey.split('-')[1] < +a.jiraKey.split('-')[1]) ? 1 : -1);
    },
    hasLineItemErrors() {
      return !!this.$store.state.lineItem.lineItemErrors.length;
    }
  },
  methods: {
    async fetchProjects() {
      const projects = await Project
        .where({ customer: true })
        .where({ resolved: true })
        .where({ billed: false })
        .where({ billable: true })
        .order('jiraKey')
        .all();
      this.projects = projects.data;
    },
    async fetchIssuesForProject(activeProject, { refresh } = {}) {
      this.deactivateIssues();

      if (!refresh && this.activeProject === activeProject) {
        this.deactivateProject();
      } else {
        this.$store.dispatch('lineItem/setCustomers', (await Customer
          .where({ id: activeProject.customerIds })
          .includes(['service_contracts'])
          .all()
        ).data);

        const issues = (await Issue
          .where({ resolved: true })
          .where({ billable: true })
          .where({ billed: false })
          .where({ project_id: activeProject.id })
          .all()
        ).data;

        const ancestorIds = [...new Set(issues.flatMap(i => i.ancestorIds))];
        let parentIssues = [];
        if (ancestorIds.length) {
          parentIssues = (await Issue
            .where({ id: ancestorIds })
            .all()
          ).data;
        }

        this.issuesForProject = issues.concat(parentIssues);

        this.activeProject = activeProject;
      }
    },
    // this is practically a toggleIssue()
    async activateIssue(issue) {
      // TODO: implement toast message system?
      this.successNote = '';

      const isIssueActive = this.activeIssues.includes(issue);

      const findChildren = (issue) => {
        let children = this.allIssuesForProject
          .filter(i => i.parentId === issue.id);
        const subChildren = children
          .reduce((subChildren, i) => subChildren.concat(findChildren(i)), []);
        children = children.concat(subChildren);

        return children;
      };

      let issues = findChildren(issue);
      issues.push(issue);

      if (isIssueActive) {
        this.deactivateIssues(issues);
      } else {
        // ancestors and non-root siblings whose parent is active should stay active
        const activeParentsAndSiblings = this.activeIssues
          .filter((ai,_,all) => !issues.includes(ai) && issues.some(i => i.ancestorIds.includes(ai.id) || (i.parentId && ai.parentId === i.parentId && all.some(a => a.id === i.parentId))));
        issues = issues.concat(activeParentsAndSiblings);

        const worklogs = await this.fetchWorklogs(issues.map(i => i.id));

        this.$store.dispatch('lineItem/buildLineItem', { issues, worklogs, customers: this.activeCustomers });
      }
    },
    async deactivateIssues(activatedIssues = []) {
      const activeIssues = this.activeIssues.filter(i => !activatedIssues.includes(i));

      if (!activatedIssues.length || !activeIssues.length) {
        // deactivate all issues
        this.$store.dispatch('lineItem/resetLineItem');
      } else {
        const worklogs = await this.fetchWorklogs(activeIssues.map(i => i.id));

        this.$store.dispatch('lineItem/buildLineItem', { issues: activeIssues, worklogs, customers: this.activeCustomers });
      }
    },
    async fetchWorklogs(issueIds) {
      return (await Worklog
        .includes('jiraUser')
        .where({ issue_id: issueIds })
        .order('started_at')
        .all()
      ).data;
    },
    deactivateProject() {
      this.activeProject = {};
      this.$store.dispatch('lineItem/setCustomers', []);
    },
    async saveLineItem() {
      if (await this.$store.dispatch('lineItem/createLineItem')) {
        this.deactivateIssues();
        await this.fetchIssuesForProject(this.activeProject, { refresh: true });
        if (!this.issuesForProject.length) {
          this.deactivateProject();
          this.fetchProjects();
        }
        this.successNote = 'Rechnungsposition wurde erfolgreich erstellt!';
      }
    }
  }
}
</script>
