<template>
  <div>
    <div id="nav" class="h-20 px-8 border-b leading-10 border-gray-300 bg-white flex place-content-between items-center">
      <div class="flex items-center">
        <router-link :to="{ name: 'Home' }" class="flex-none font-semibold text-gray-500 hover:text-gray-600 px-0.5">
          <LiaLogo class="h-10" />
        </router-link>
      </div>
      <template v-if="$store.getters.isAuthenticated">
        <div class="ml-10 flex items-center space-x-4">
          <div v-if="$store.getters.isAuthenticated" class="flex items-center space-x-2">
            <router-link v-if="false && currentUser.isAdmin()"
                         :to="{ name: 'openIssues' }"
                         class="p-2 bg-black rounded text-white text-sm font-medium uppercase relative m-bar">
              <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 512 512" class="fill-white	h-6 w-6 flex-shrink-0">
                <path d="M512 80c0 18-14.3 34.6-38.4 48c-29.1 16.1-72.5 27.5-122.3 30.9c-3.7-1.8-7.4-3.5-11.3-5C300.6 137.4 248.2 128 192 128c-8.3 0-16.4 .2-24.5 .6l-1.1-.6C142.3 114.6 128 98 128 80c0-44.2 86-80 192-80S512 35.8 512 80zM160.7 161.1c10.2-.7 20.7-1.1 31.3-1.1c62.2 0 117.4 12.3 152.5 31.4C369.3 204.9 384 221.7 384 240c0 4-.7 7.9-2.1 11.7c-4.6 13.2-17 25.3-35 35.5c0 0 0 0 0 0c-.1 .1-.3 .1-.4 .2l0 0 0 0c-.3 .2-.6 .3-.9 .5c-35 19.4-90.8 32-153.6 32c-59.6 0-112.9-11.3-148.2-29.1c-1.9-.9-3.7-1.9-5.5-2.9C14.3 274.6 0 258 0 240c0-34.8 53.4-64.5 128-75.4c10.5-1.5 21.4-2.7 32.7-3.5zM416 240c0-21.9-10.6-39.9-24.1-53.4c28.3-4.4 54.2-11.4 76.2-20.5c16.3-6.8 31.5-15.2 43.9-25.5V176c0 19.3-16.5 37.1-43.8 50.9c-14.6 7.4-32.4 13.7-52.4 18.5c.1-1.8 .2-3.5 .2-5.3zm-32 96c0 18-14.3 34.6-38.4 48c-1.8 1-3.6 1.9-5.5 2.9C304.9 404.7 251.6 416 192 416c-62.8 0-118.6-12.6-153.6-32C14.3 370.6 0 354 0 336V300.6c12.5 10.3 27.6 18.7 43.9 25.5C83.4 342.6 135.8 352 192 352s108.6-9.4 148.1-25.9c7.8-3.2 15.3-6.9 22.4-10.9c6.1-3.4 11.8-7.2 17.2-11.2c1.5-1.1 2.9-2.3 4.3-3.4V304v5.7V336zm32 0V304 278.1c19-4.2 36.5-9.5 52.1-16c16.3-6.8 31.5-15.2 43.9-25.5V272c0 10.5-5 21-14.9 30.9c-16.3 16.3-45 29.7-81.3 38.4c.1-1.7 .2-3.5 .2-5.3zM192 448c56.2 0 108.6-9.4 148.1-25.9c16.3-6.8 31.5-15.2 43.9-25.5V432c0 44.2-86 80-192 80S0 476.2 0 432V396.6c12.5 10.3 27.6 18.7 43.9 25.5C83.4 438.6 135.8 448 192 448z"/>
              </svg>
              <div class="m-bar__text absolute top-full	mt-3 right-1/2 translate-x-1/2 p-2 bg-black rounded w-40 text-center text-white invisible opacity-0">
                <p class="text-xs font-medium leading-normal">Teilabrechnung laufender Tickets</p>
              </div>
            </router-link>
            <router-link v-if="currentUser.isProductManager()"
                         :to="{ name: 'openIssues' }"
                         class="p-2 bg-black rounded text-white text-sm font-medium uppercase relative m-bar">
              <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 512 512" class="fill-white	h-6 w-6 flex-shrink-0">
                <path d="M512 80c0 18-14.3 34.6-38.4 48c-29.1 16.1-72.5 27.5-122.3 30.9c-3.7-1.8-7.4-3.5-11.3-5C300.6 137.4 248.2 128 192 128c-8.3 0-16.4 .2-24.5 .6l-1.1-.6C142.3 114.6 128 98 128 80c0-44.2 86-80 192-80S512 35.8 512 80zM160.7 161.1c10.2-.7 20.7-1.1 31.3-1.1c62.2 0 117.4 12.3 152.5 31.4C369.3 204.9 384 221.7 384 240c0 4-.7 7.9-2.1 11.7c-4.6 13.2-17 25.3-35 35.5c0 0 0 0 0 0c-.1 .1-.3 .1-.4 .2l0 0 0 0c-.3 .2-.6 .3-.9 .5c-35 19.4-90.8 32-153.6 32c-59.6 0-112.9-11.3-148.2-29.1c-1.9-.9-3.7-1.9-5.5-2.9C14.3 274.6 0 258 0 240c0-34.8 53.4-64.5 128-75.4c10.5-1.5 21.4-2.7 32.7-3.5zM416 240c0-21.9-10.6-39.9-24.1-53.4c28.3-4.4 54.2-11.4 76.2-20.5c16.3-6.8 31.5-15.2 43.9-25.5V176c0 19.3-16.5 37.1-43.8 50.9c-14.6 7.4-32.4 13.7-52.4 18.5c.1-1.8 .2-3.5 .2-5.3zm-32 96c0 18-14.3 34.6-38.4 48c-1.8 1-3.6 1.9-5.5 2.9C304.9 404.7 251.6 416 192 416c-62.8 0-118.6-12.6-153.6-32C14.3 370.6 0 354 0 336V300.6c12.5 10.3 27.6 18.7 43.9 25.5C83.4 342.6 135.8 352 192 352s108.6-9.4 148.1-25.9c7.8-3.2 15.3-6.9 22.4-10.9c6.1-3.4 11.8-7.2 17.2-11.2c1.5-1.1 2.9-2.3 4.3-3.4V304v5.7V336zm32 0V304 278.1c19-4.2 36.5-9.5 52.1-16c16.3-6.8 31.5-15.2 43.9-25.5V272c0 10.5-5 21-14.9 30.9c-16.3 16.3-45 29.7-81.3 38.4c.1-1.7 .2-3.5 .2-5.3zM192 448c56.2 0 108.6-9.4 148.1-25.9c16.3-6.8 31.5-15.2 43.9-25.5V432c0 44.2-86 80-192 80S0 476.2 0 432V396.6c12.5 10.3 27.6 18.7 43.9 25.5C83.4 438.6 135.8 448 192 448z"/>
              </svg>
              <div class="m-bar__text absolute top-full	mt-3 right-1/2 translate-x-1/2 p-2 bg-black rounded w-40 text-center text-white invisible opacity-0">
                <p class="text-xs font-medium leading-normal">Abrechnung offener Tickets</p>
              </div>
            </router-link>
            <router-link v-if="currentUser.isProductManager()"
                         :to="{ name: 'closedIssues' }"
                         class="p-2 bg-black rounded text-white text-sm font-medium uppercase relative m-bar">
              <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24" class="fill-white	h-6 w-6 flex-shrink-0">
                <path d="M18.375 2.25c-1.035 0-1.875.84-1.875 1.875v15.75c0 1.035.84 1.875 1.875 1.875h.75c1.035 0 1.875-.84 1.875-1.875V4.125c0-1.036-.84-1.875-1.875-1.875h-.75zM9.75 8.625c0-1.036.84-1.875 1.875-1.875h.75c1.036 0 1.875.84 1.875 1.875v11.25c0 1.035-.84 1.875-1.875 1.875h-.75a1.875 1.875 0 01-1.875-1.875V8.625zM3 13.125c0-1.036.84-1.875 1.875-1.875h.75c1.036 0 1.875.84 1.875 1.875v6.75c0 1.035-.84 1.875-1.875 1.875h-.75A1.875 1.875 0 013 19.875v-6.75z" />
              </svg>
              <div class="m-bar__text absolute top-full	mt-3 right-1/2 translate-x-1/2 p-2 bg-black rounded w-40 text-center text-white invisible opacity-0">
                <p class="text-xs font-medium leading-normal">Abrechnung abgeschlossener Tickets</p>
              </div>
            </router-link>
            <router-link v-if="currentUser.isProductManager()"
                         :to="{ name: 'Invoices' }"
                         class="p-2 bg-black rounded text-white text-sm font-medium uppercase relative m-bar">
              <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24" class="fill-white	h-6 w-6 flex-shrink-0">
                <path fill-rule="evenodd" d="M9.75 6.75h-3a3 3 0 00-3 3v7.5a3 3 0 003 3h7.5a3 3 0 003-3v-7.5a3 3 0 00-3-3h-3V1.5a.75.75 0 00-1.5 0v5.25zm0 0h1.5v5.69l1.72-1.72a.75.75 0 111.06 1.06l-3 3a.75.75 0 01-1.06 0l-3-3a.75.75 0 111.06-1.06l1.72 1.72V6.75z" clip-rule="evenodd" />
                <path d="M7.151 21.75a2.999 2.999 0 002.599 1.5h7.5a3 3 0 003-3v-7.5c0-1.11-.603-2.08-1.5-2.599v7.099a4.5 4.5 0 01-4.5 4.5H7.151z" />
              </svg>
              <div class="m-bar__text absolute top-full	mt-3 right-1/2 translate-x-1/2 p-2 bg-black rounded w-40 text-center text-white invisible opacity-0">
                <p class="text-xs font-medium leading-normal">Rechnungen zusammenstellen</p>
              </div>
            </router-link>
            <router-link v-if="currentUser.isBackoffice()"
                         :to="{ name: 'lineItems' }"
                         class="p-2 bg-black rounded text-white text-sm font-medium uppercase relative m-bar">
              <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24" class="fill-white	h-6 w-6 flex-shrink-0">
                <path fill-rule="evenodd" d="M6.32 1.827a49.255 49.255 0 0111.36 0c1.497.174 2.57 1.46 2.57 2.93V19.5a3 3 0 01-3 3H6.75a3 3 0 01-3-3V4.757c0-1.47 1.073-2.756 2.57-2.93zM7.5 11.25a.75.75 0 01.75-.75h.008a.75.75 0 01.75.75v.008a.75.75 0 01-.75.75H8.25a.75.75 0 01-.75-.75v-.008zm.75 1.5a.75.75 0 00-.75.75v.008c0 .414.336.75.75.75h.008a.75.75 0 00.75-.75V13.5a.75.75 0 00-.75-.75H8.25zm-.75 3a.75.75 0 01.75-.75h.008a.75.75 0 01.75.75v.008a.75.75 0 01-.75.75H8.25a.75.75 0 01-.75-.75v-.008zm.75 1.5a.75.75 0 00-.75.75v.008c0 .414.336.75.75.75h.008a.75.75 0 00.75-.75V18a.75.75 0 00-.75-.75H8.25zm1.748-6a.75.75 0 01.75-.75h.007a.75.75 0 01.75.75v.008a.75.75 0 01-.75.75h-.007a.75.75 0 01-.75-.75v-.008zm.75 1.5a.75.75 0 00-.75.75v.008c0 .414.335.75.75.75h.007a.75.75 0 00.75-.75V13.5a.75.75 0 00-.75-.75h-.007zm-.75 3a.75.75 0 01.75-.75h.007a.75.75 0 01.75.75v.008a.75.75 0 01-.75.75h-.007a.75.75 0 01-.75-.75v-.008zm.75 1.5a.75.75 0 00-.75.75v.008c0 .414.335.75.75.75h.007a.75.75 0 00.75-.75V18a.75.75 0 00-.75-.75h-.007zm1.754-6a.75.75 0 01.75-.75h.008a.75.75 0 01.75.75v.008a.75.75 0 01-.75.75h-.008a.75.75 0 01-.75-.75v-.008zm.75 1.5a.75.75 0 00-.75.75v.008c0 .414.336.75.75.75h.008a.75.75 0 00.75-.75V13.5a.75.75 0 00-.75-.75h-.008zm-.75 3a.75.75 0 01.75-.75h.008a.75.75 0 01.75.75v.008a.75.75 0 01-.75.75h-.008a.75.75 0 01-.75-.75v-.008zm.75 1.5a.75.75 0 00-.75.75v.008c0 .414.336.75.75.75h.008a.75.75 0 00.75-.75V18a.75.75 0 00-.75-.75h-.008zm1.748-6a.75.75 0 01.75-.75h.008a.75.75 0 01.75.75v.008a.75.75 0 01-.75.75h-.008a.75.75 0 01-.75-.75v-.008zm.75 1.5a.75.75 0 00-.75.75v.008c0 .414.336.75.75.75h.008a.75.75 0 00.75-.75V13.5a.75.75 0 00-.75-.75h-.008zm-8.25-6A.75.75 0 018.25 6h7.5a.75.75 0 01.75.75v.75a.75.75 0 01-.75.75h-7.5a.75.75 0 01-.75-.75v-.75zm9 9a.75.75 0 00-1.5 0V18a.75.75 0 001.5 0v-2.25z" clip-rule="evenodd" />
              </svg>
              <div class="m-bar__text absolute top-full	mt-3 right-1/2 translate-x-1/2 p-2 bg-black rounded w-40 text-center text-white invisible opacity-0">
                <p class="text-xs font-medium leading-normal">Rechnungspositionen buchen</p>
              </div>
            </router-link>
            <router-link v-if="currentUser.isBackoffice()"
                         :to="{ name: 'LineItemsReport' }"
                         class="p-2 bg-black rounded text-white text-sm font-medium uppercase relative m-bar">
              <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24" class="fill-white	h-6 w-6 flex-shrink-0">
                <path fill-rule="evenodd" d="M2.25 2.25a.75.75 0 000 1.5H3v10.5a3 3 0 003 3h1.21l-1.172 3.513a.75.75 0 001.424.474l.329-.987h8.418l.33.987a.75.75 0 001.422-.474l-1.17-3.513H18a3 3 0 003-3V3.75h.75a.75.75 0 000-1.5H2.25zm6.04 16.5l.5-1.5h6.42l.5 1.5H8.29zm7.46-12a.75.75 0 00-1.5 0v6a.75.75 0 001.5 0v-6zm-3 2.25a.75.75 0 00-1.5 0v3.75a.75.75 0 001.5 0V9zm-3 2.25a.75.75 0 00-1.5 0v1.5a.75.75 0 001.5 0v-1.5z" clip-rule="evenodd" />
              </svg>
              <div class="m-bar__text absolute top-full	mt-3 right-1/2 translate-x-1/2 p-2 bg-black rounded w-40 text-center text-white invisible opacity-0">
                <p class="text-xs font-medium leading-normal">Tagesübersicht gebuchter Rechnungspositionen</p>
              </div>
            </router-link>
          </div>
          <button class="flex items-center py-2 px-3 bg-black rounded text-white text-sm font-medium uppercase"
                  type="button"
                  v-if="$store.getters.isAuthenticated"
                  @click="logout">
            <LogoutIcon class="inline h-6 w-6 mr-2" />
            Logout
          </button>
        </div>
      </template>
    </div>
  </div>
</template>

<style>
.m-bar:hover .m-bar__text {
  opacity: 1;
  visibility: visible;
}

</style>

<script>
import LiaLogo from "@/components/LiaLogo";
import { LogoutIcon } from "@vue-hero-icons/solid"
import { TrendingUpIcon } from "@vue-hero-icons/solid"

export default {
  components: {
    LiaLogo,
    LogoutIcon,
    TrendingUpIcon
  },
  data() {
    return {
      menuOpen: false,
    }
  },
  computed: {
    currentUser() { return this.$store.getters.currentUser; }
  },
  methods: {
    logout() {
      fetch('/api/v1/users/sign_out', {
        method: 'DELETE',
        headers: { Authorization: 'Bearer ' + this.$store.getters.token }
      }).finally(() => {
        localStorage.removeItem('jwt');
         window.location.href = '/login';
      })
    }
  }
}
</script>
