<template>
  <div>
    <div class="grid grid-cols-12 gap-6 items-start">
      <DataRow class="col-span-12">
        <DataLabel>Verwendungszweck</DataLabel>
        <LineItemTitle v-if="editable" />
        <DataInline v-else>{{ lineItem.title }}</DataInline>
      </DataRow>

      <div class="col-span-4">
        <DataRow>
          <DataLabel>Kunde</DataLabel>
          <CustomerSelect v-if="editable" />
          <DataInline v-else>{{ customer.displayTitle() }}</DataInline>
        </DataRow>
      </div>

      <DataRow class="col-span-3" v-if="editable && $store.getters['lineItem/availableServiceContracts'].length">
        <DataLabel>Wartungsvertrag</DataLabel>
        <select v-model="selectedServiceContract"
                class="mt-2 px-3 w-full py-2 bg-white border border-neutral-300 rounded">
          <option :value="undefined">Kein Wartungsvertrag</option>
          <option v-for="serviceContract in $store.getters['lineItem/availableServiceContracts']"
                  :key="serviceContract.id"
                  :value="serviceContract">
            {{ serviceContract.name }}
          </option>
        </select>
      </DataRow>
      <DataRow class="col-span-3" v-else>
        <DataLabel>Wartungsvertrag</DataLabel>
        <div class="mt-2 px-3 w-full py-2 border bg-neutral-100 text-neutral-500 border-neutral-300 rounded">
          Kein Wartungsvertrag verfügbar
        </div>
      </DataRow>

      <DataRow class="col-span-3">
        <DataLabel>
          abzurechnender Aufwand
          (<LineItemSum :amount="lineItem.amount" />)
        </DataLabel>
        <BillTime v-if="editable" />
        <DataInline v-else>{{ prettyTime(lineItem.billedTimeInSeconds) }} </DataInline>
      </DataRow>

      <DataRow class="col-span-2">
        <DataLabel>Stundensatz in €</DataLabel>
        <template v-if="editable">
          <LineItemHourlyRateSelect />
          <SecondaryActionButton class="text-orange-400 hover:text-orange-600 flex mt-2 items-center"
                                  v-if="showHourlyRateResetBtn"
                                 @click="$store.dispatch('lineItem/updateHourlyRate', customer.hourlyRate);">
            Stundensatz des Kunden verwenden
          </SecondaryActionButton>
        </template>
        <Currency :amount="lineItem.hourlyRate" v-else />
      </DataRow>

      <DataRow class="col-span-12">
        <textarea v-if="editable && lineItem.billedTimeInSeconds < timeSpentInSeconds"
                  rows="3"
                  placeholder="Hinweis für den geringeren abgerechneten Aufwand"
                  :value="$store.state.lineItem.lineItemBilledAmountExplanation"
                  @input="$store.dispatch('lineItem/updateBilledAmountExplanation', $event.target.value)"
                  class="mt-1 px-3 block w-full py-2 border border-neutral-300 rounded"
                  :class="[!lineItemErrors.includes('billedAmountExplanation') ? 'bg-white' : 'bg-red-200']"
                  @blur="$store.dispatch('lineItem/validateLineItem')">
        </textarea>
        <DataInline v-else>{{ lineItem.billedAmountExplanation }}</DataInline>
      </DataRow>

      <div class="col-span-12 rounded-md bg-red-50 p-4"
          v-if="warnings.length">
        <div class="flex">
          <div class="flex-shrink-0 mt-0.5">
            <!-- Heroicon name: mini/x-circle -->
            <svg class="h-5 w-5 text-red-400" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 20 20" fill="currentColor" aria-hidden="true">
              <path fill-rule="evenodd" d="M10 18a8 8 0 100-16 8 8 0 000 16zM8.28 7.22a.75.75 0 00-1.06 1.06L8.94 10l-1.72 1.72a.75.75 0 101.06 1.06L10 11.06l1.72 1.72a.75.75 0 101.06-1.06L11.06 10l1.72-1.72a.75.75 0 00-1.06-1.06L10 8.94 8.28 7.22z" clip-rule="evenodd" />
            </svg>
          </div>
          <div class="ml-3">
            <DataRow>
              <DataLabel class="text-base font-medium text-red-800">Hinweise</DataLabel>
            </DataRow>
            <DataRow class="mt-2 text-sm text-red-700">
              <ul role="list" class="list-disc space-y-1 pl-5">
                <li v-for="(warning, idx) in warnings"
                    :key="idx" >
                  {{ warning }}
                </li>
              </ul>
            </DataRow>
          </div>
        </div>
      </div>

      <DataRow class="col-span-12">
        <textarea v-if="editable"
                  rows="3"
                  placeholder="Hinweis für die Buchhaltung"
                  :value="$store.state.lineItem.lineItemInternalNote"
                  @input="$store.dispatch('lineItem/updateInternalNote', $event.target.value)"
                  class="mt-1 px-3 block w-full py-2 bg-white border border-neutral-300 rounded">
        </textarea>
        <DataInline v-else>{{ lineItem.internalNote }}</DataInline>
      </DataRow>
    </div>
  </div>
</template>

<script>
import timeHelpers from '@/lib/time_helpers';

import LineItemTitle from '@/components/LineItemTitle';
import LineItemSum from '@/components/LineItemSum';
import BillTime from '@/components/BillTime';
import CustomerSelect from '@/components/CustomerSelect';
import DataRow from '@/components/DataRow';
import DataLabel from '@/components/DataLabel';
import DataInline from '@/components/DataInline';
import Currency from '@/components/Currency';
import SecondaryActionButton from '@/components/SecondaryActionButton';
import LineItemHourlyRateSelect from '@/components/LineItemHourlyRateSelect';

export default {
  components: {
    LineItemTitle,
    LineItemSum,
    BillTime,
    CustomerSelect,
    DataRow,
    DataLabel,
    DataInline,
    Currency,
    SecondaryActionButton,
    LineItemHourlyRateSelect
  },
  props: ['issuesData', 'editable'],
  methods: {
    prettyTime: timeHelpers.prettyTime
  },
  computed: {
    selectedServiceContract: {
      get() { return this.$store.state.lineItem.lineItemServiceContract; },
      set(serviceContract) { this.$store.dispatch('lineItem/updateLineItemServiceContract', serviceContract); }
    },
    useServiceContract() {
      return this.$store.getters['lineItem/useServiceContract'];
    },
    customer() { return this.$store.state.lineItem.lineItemSelectedCustomer; },
    issues() { return this.$store.state.lineItem.issues; },
    hourlyRates() { return this.$store.getters['lineItem/availableHourlyRates']; },
    lineItem() { return this.$store.state.lineItem.lineItem; },
    showHourlyRateResetBtn() {
      if (!this.useServiceContract && this.lineItem.hourlyRate != this.customer.hourlyRate) {
        return true;
      } else {
        return false;
      }
    },
    timeSpentInSeconds() {
      return this.$store.getters['lineItem/timeSpentInSeconds'];
    },
    offerAmountInSeconds() {
      return this.$store.getters['lineItem/offerAmountInSeconds'];
    },
    warnings() {
      const warnings = [];

      if (this.lineItem.billedTimeInSeconds < this.timeSpentInSeconds) {
        warnings.push('Abgerechneter Aufwand ist kleiner als angefallener Aufwand.');
      }
      if (this.lineItem.billedTimeInSeconds < this.offerAmountInSeconds) {
        warnings.push('Abgerechneter Aufwand ist kleiner als angebotener Aufwand.');
      }
      if (!this.useServiceContract && this.lineItem.hourlyRate < this.customer.hourlyRate) {
        warnings.push('Stundensatz ist geringer als Stundensatz des Kunden.');
      }
      if (!this.useServiceContract && this.lineItem.hourlyRate != this.customer.hourlyRate) {
        warnings.push('Stundensatz weicht ab.');
      }
      if (this.useServiceContract && this.issues.some(issue => !issue.serviceContract)) {
        const issueIds = this.issues.filter(issue => !issue.serviceContract).map(issue => issue.jiraKey)
        warnings.push(`Jira Tickets ohne Abrechnung über Wartungsvertrag: ${issueIds.join(', ')}`);
      }

      const closedIssuesWithRemainingEstimates = this.issues
        .filter(i => i.resolvedAt && i.remainingEstimateInSeconds > 0);

      if (closedIssuesWithRemainingEstimates.length) {
        warnings.push(`Geschlossene Tickets mit eingetragenem Restaufwand: ${closedIssuesWithRemainingEstimates.map(i => i.jiraKey).join(', ')}`);
      }

      return warnings;
    },
    lineItemErrors() {
      return this.$store.state.lineItem.lineItemErrors;
    }
  }
}
</script>
