<template>
  <AppContainer>
    <div class="flex flex-col pt-8">
      <div class="pb-3">
        <SectionHeading>Tagesübersicht gebuchter Rechnungspositionen</SectionHeading>
      </div>
      <div v-if="!customers.length">
        <span class="py-1">keine neuen Buchungen</span>
      </div>
      <ScrollContainer v-else>
        <table class="bg-gray-50 border-collapse w-full table-auto whitespace-nowrap">
          <thead>
            <tr class="divide-x divide-gray-300 leading-7">
              <th class="sticky top-0 bg-gray-200 text-gray-500 font-medium p-1 text-left w-20">Nr.</th>
              <th class="sticky top-0 bg-gray-200 text-gray-500 font-medium p-1 text-left">Kunde</th>
            </tr>
          </thead>
          <tbody>
            <template v-for="(customer, idx) in customers">
              <tr :key="customer.id"
                  class="hover:bg-gray-200 text-gray-600 hover:text-gray-800 cursor-pointer"
                  :class="[idx % 2 === 1 ? 'bg-gray-100 text-gray-800' : '']"
                  @click="fetchLineItemsForCustomer(customer)">

                <td class="p-1">
                  {{ customer.number }}
                </td>
                <td class="p-1">
                  {{ customer.name }}
                </td>
              </tr>
              <tr v-if="customer === activeCustomer"
                  :key="customer.id + '_lineItems'">
                <td colspan="2" class="py-0.5 px-1">
                  <table class="border-collapse w-full table-auto border border-gray-300">
                    <tbody>
                      <template v-for="(lineItems, invoiceId, idx) in lineItemsByInvoice">
                        <tr class="text-gray-800 bg-gray-300"
                            :key="invoiceId">
                          <td class="p-1 border-t-2 border-b-2 border-gray-400" colspan="3">
                            Rechnung #{{ idx + 1 }}
                            <span v-if="lineItems[0].invoice && lineItems[0].invoice.internalNote">
                              - {{ lineItems[0].invoice.internalNote }}
                            </span>
                          </td>
                        </tr>
                        <template v-for="(lineItem, idx) in lineItems">
                          <tr class="text-gray-800"
                              :class="[idx % 2 === 0 ? 'bg-gray-200' : 'bg-gray-100' ]"
                              :key="lineItem.id">
                            <td v-if="lineItem.serviceContract">
                              WV: {{ lineItem.serviceContract.name }}
                            </td>
                            <td v-else>
                              -
                            </td>
                            <td class="p-1">
                              <button type="button"
                                      title="copy"
                                      class="text-gray-600 hover:text-black align-middle">
                                <ClipboardCopyIcon @click="navigator.clipboard.writeText(lineItem.title)" />
                              </button>
                              <span class="align-bottom">
                              {{ lineItem.title }}
                              </span>
                            </td>
                            <td class="p-1">
                              <button type="button"
                                      title="copy"
                                      class="text-gray-600 hover:text-black align-middle">
                                <ClipboardCopyIcon @click="navigator.clipboard.writeText(lineItem.amount)" />
                              </button>
                              <Currency :amount="lineItem.amount" class="align-bottom" />
                            </td>
                          </tr>
                        </template>
                      </template>
                    </tbody>
                  </table>
                </td>
              </tr>
            </template>
          </tbody>
        </table>
      </ScrollContainer>
    </div>
  </AppContainer>
</template>

<style>
</style>

<script>
import AppContainer from '@/components/AppContainer';
import SectionHeading from '@/components/SectionHeading';
import ScrollContainer from '@/components/ScrollContainer';
import { ClipboardCopyIcon } from "@vue-hero-icons/solid"
import Currency from '@/components/Currency';

import Customer from '@/models/Customer';
import LineItem from '@/models/LineItem';

export default {
  name: 'LineItems',
  components: {
    AppContainer,
    SectionHeading,
    ScrollContainer,
    ClipboardCopyIcon,
    Currency
  },
  data() {
    return {
      customers: [],
      activeCustomer: {},
      lineItemsForCustomer: [],
      navigator: window.navigator,
    };
  },
  async created() {
    this.fetchCustomers();
  },
  computed: {
    currentUser() { return this.$store.getters.currentUser; },
    lineItemsByInvoice() {
      return this.lineItemsForCustomer.reduce((obj, line) => {
        obj[line.invoiceId] ||= [];
        obj[line.invoiceId].push(line);

        return obj
      }, {});
    }
  },
  methods: {
    async fetchCustomers() {
      const customers = await Customer
        .includes(['serviceContracts'])
        .where({ unbooked: false }) // only customers /w booked lines (from today implicitly)
        .order('name')
        .all();
      this.customers = customers.data;
    },
    async fetchLineItemsForCustomer(activeCustomer, { refresh } = {}) {
      if (!refresh && this.activeCustomer.id === activeCustomer.id) {
        this.deactivateCustomer();
      } else {
        this.lineItemsForCustomer = (await LineItem
          .includes(['serviceContract', 'invoice'])
          .where({ customer_id: activeCustomer.id })
          .where({ booked: true }) // only booked lines (from today implicitly)
          .all()).data;

        this.activeCustomer = activeCustomer;
      }
    },
    deactivateCustomer() {
      this.activeCustomer = {};
    }
  }
}
</script>
