import { attr, hasMany, belongsTo } from 'spraypaint/dist/spraypaint';
import ApplicationRecord from '@/models/ApplicationRecord';

const Project = ApplicationRecord.extend({
  static: {
    jsonapiType: 'projects',
  },
  attrs: {
    customerId: attr({ persist: false }),
    customerIds: attr({ persist: false }),

    name: attr({ persist: false }),
    jiraKey: attr({ persist: false }),
    jiraId: attr({ persist: false }),
    enableInvoiceManagement: attr({ persist: false }),
    enableManualWorklogSelection: attr({ persist: false} ),

    issues: hasMany(),
    customer: hasMany()
  }
});

export default Project
