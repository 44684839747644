import timeHelpers from '@/lib/time_helpers';

import LineItem from '@/models/LineItem';

const AVAILABLE_HOURLY_RATES = [140, 125, 120, 110, 100, 90];

function calculateLineItemAmount(hourlyRate = 0, timeInSeconds = 0) {
  return +(Math.round((hourlyRate * timeInSeconds / 3600) + 'e2') + 'e-2');
}
function roundSecondsToFullQuarters(seconds = 0) {
  return Math.ceil(seconds / 60 / 15) * 60 * 15;
}

const numberFormatter = new Intl.NumberFormat('de-DE', { style: 'currency', currency: 'EUR' });

export default {
  namespaced: true,

  state: {
    lineItem: {},

    customers: [],
    issues: [],
    worklogs: [],

    lineItemSelectedWorklogs: [],
    lineItemSelectedCustomer: {},
    lineItemBilledTimeInSeconds: 0,
    lineItemHourlyRate: 0,
    lineItemInternalNote: '',
    lineItemSummary: '', // used to construct title
    lineItemDeliveryDate: '', // used to construct title
    lineItemServiceContract: undefined,
    lineItemBilledAmountExplanation: '',
    lineItemErrors: [],
  },
  mutations: {
    setLineItem(state, lineItem) { state.lineItem = lineItem; },
    setIssues(state, issues) { state.issues = issues; },
    setCustomers(state, customers) { state.customers = customers; },
    setWorklogs(state, worklogs) { state.worklogs = worklogs; },
    setSelectedWorklogs(state, worklogs) { state.lineItemSelectedWorklogs = worklogs; },
    setSelectedCustomer(state, customer) { state.lineItemSelectedCustomer = customer; },
    setBilledTimeInSeconds(state, seconds) { state.lineItemBilledTimeInSeconds = seconds; },
    setHourlyRate(state, rate) { state.lineItemHourlyRate = rate; },
    setLineItemSummary(state, summary) { state.lineItemSummary = summary },
    setLineItemDeliveryDate(state, prettyDeliveryDate) { state.lineItemDeliveryDate = prettyDeliveryDate; },
    setLineItemInternalNote(state, note) { state.lineItemInternalNote = note; },
    setLineItemServiceContract(state, serviceContract) { state.lineItemServiceContract = serviceContract; },
    setLineItemBilledAmountExplanation(state, explanation) { state.lineItemBilledAmountExplanation = explanation; },
    setLineItemErrors(state, errors) { state.lineItemErrors = errors; },

    // setActiveServiceContract(state, serviceContract) {
    //   state.lineItem.serviceContract = serviceContract;
    //   state.activeServiceContract = serviceContract;
    //   hourlyRates should be [rate of activeServiceContract]
    // },
  },
  actions: {
    setCustomers({ commit }, customers) { commit('setCustomers', customers) },

    async createLineItem({ state, dispatch }) {
      const valid = await dispatch('validateLineItem')
      if (valid) {
        // causes mutation outside of mutation handlers...
        return state.lineItem.save({ with: ['worklogs.id', 'creator.id', 'customer.id', 'serviceContract.id'] });
      }
      return false
    },
    validateLineItem({ state, commit, getters }) {
      const errors = [];

      if (!state.lineItem.deliveryDate) {
        errors.push('deliveryDate')
      }
      if (!state.lineItem.titleSummary) {
        errors.push('titleSummary')
      }
      if (!state.lineItem.billedTimeInSeconds) {
        errors.push('billedTimeInSeconds')
      }
      if (state.lineItem.billedTimeInSeconds < getters.timeSpentInSeconds && !state.lineItem.billedAmountExplanation) {
        errors.push('billedAmountExplanation')
      }

      commit('setLineItemErrors', errors)

      return !errors.length
    },

    async bookLineItem({ state, dispatch, rootGetters }) {
      // TODO: set these server-side!
      state.lineItem.booker = rootGetters.currentUser;
      state.lineItem.bookedAt = (new Date()).toISOString();

      const valid = await dispatch('validateLineItem')
      if (valid) {
        // causes mutation outside of mutation handlers...
        return state.lineItem.save({ with: ['booker.id', 'serviceContract.id'] });
      }
      return false
    },

    resetLineItem({ commit }) {
      commit('setLineItem', {});
      commit('setIssues', []);
      commit('setWorklogs', []);

      commit('setSelectedWorklogs', []);
      commit('setSelectedCustomer', {});
      commit('setHourlyRate', 0);
      commit('setBilledTimeInSeconds', 0);
      commit('setLineItemDeliveryDate', '');
      commit('setLineItemSummary', '');
      commit('setLineItemInternalNote', '');
      commit('setLineItemServiceContract', undefined);
      commit('setLineItemBilledAmountExplanation', '');
      commit('setLineItemErrors', []);
    },

    loadLineItem({ dispatch, commit, getters }, { lineItem, customers, worklogs, selectedCustomer }) {
      commit('setSelectedCustomer', selectedCustomer);
      commit('setCustomers', customers);
      commit('setWorklogs', worklogs);
      commit('setSelectedWorklogs', worklogs);
      commit('setHourlyRate', lineItem.hourlyRate);
      commit('setBilledTimeInSeconds', lineItem.billedTimeInSeconds);
      commit('setLineItemInternalNote', lineItem.internalNote);
      commit('setLineItemServiceContract', lineItem.serviceContract);
      commit('setLineItemSummary', lineItem.titleSummary);
      commit('setLineItemDeliveryDate', lineItem.prettyDeliveryDate());
      commit('setLineItemBilledAmountExplanation', lineItem.billedAmountExplanation);

      // optimize
      const issueIds = worklogs.reduce((mem, w) => (mem[w.issue.id] = w.issue, mem), {});
      const issues = Object.values(issueIds)

      commit('setIssues', issues);
      commit('setLineItem', lineItem);

      dispatch('rebuildLineItem');
    },

    buildLineItem({ dispatch, commit, state, getters, rootGetters }, { issues, worklogs }) {
      commit('setIssues', issues);
      commit('setWorklogs', worklogs);
      commit('setSelectedWorklogs', getters.unbilledWorklogs);
      commit('setSelectedCustomer', state.customers[0]);
      commit('setHourlyRate', state.lineItemSelectedCustomer.hourlyRate);
      commit('setBilledTimeInSeconds', getters.billedTimeSuggestionInSeconds);
      commit('setLineItemDeliveryDate', getters.lastResolvedIssue.prettyDeliveryDate());
      commit('setLineItemSummary', getters.rootIssue.summary);
      commit('setLineItemInternalNote', '');
      commit('setLineItemBilledAmountExplanation', '');
      commit('setLineItemErrors', []);

      commit(
        'setLineItem',
        new LineItem({
          creator: rootGetters.currentUser
          // serviceContract
        })
      );

      dispatch('rebuildLineItem');
    },

    rebuildLineItem({ state, getters, commit }) {
      const newLineItem = state.lineItem.dup();

      newLineItem.worklogs = state.lineItemSelectedWorklogs;
      newLineItem.deliveryDate = state.lineItemDeliveryDate;
      newLineItem.titleSummary = state.lineItemSummary
      newLineItem.title = getters.title;
      newLineItem.customer = state.lineItemSelectedCustomer;
      newLineItem.billedTimeInSeconds = state.lineItemBilledTimeInSeconds;
      newLineItem.amount = getters.amount;
      newLineItem.hourlyRate = state.lineItemHourlyRate;
      newLineItem.internalNote = state.lineItemInternalNote;
      newLineItem.billedAmountExplanation = state.lineItemBilledAmountExplanation;

      if (!state.lineItemServiceContract && newLineItem.serviceContract) {
        newLineItem.serviceContract.isMarkedForDisassociation = true;
      } else {
        newLineItem.serviceContract = state.lineItemServiceContract;
        if (state.lineItemServiceContract)
          newLineItem.serviceContract.isMarkedForDisassociation = false;
      }

      commit('setLineItem', newLineItem);
    },

    toggleWorklog({ state, commit, dispatch, getters }, worklog) {
      let newWorklogs;

      if (state.lineItemSelectedWorklogs.includes(worklog)) {
        newWorklogs = state.lineItemSelectedWorklogs.filter(w => w.id !== worklog.id);
      } else {
        newWorklogs = [...state.lineItemSelectedWorklogs, worklog];
      }

      commit('setSelectedWorklogs', newWorklogs);
      commit('setBilledTimeInSeconds', getters.billedTimeSuggestionInSeconds);

      dispatch('rebuildLineItem');
    },
    updateLineItemServiceContract({ state, commit, dispatch }, serviceContract) {
      commit('setLineItemServiceContract', serviceContract);
      if (serviceContract) {
        commit('setHourlyRate', serviceContract.hourlyRate);
      } else {
        commit('setHourlyRate', state.lineItemSelectedCustomer.hourlyRate);
      }
      dispatch('rebuildLineItem');
    },
    updateInternalNote({ commit, dispatch }, note) {
      commit('setLineItemInternalNote', note);
      dispatch('rebuildLineItem');
    },
    updateBilledAmountExplanation({ commit, dispatch }, explanation) {
      commit('setLineItemBilledAmountExplanation', explanation);
      dispatch('rebuildLineItem');
    },
    updateHourlyRate({ commit, dispatch }, rate) {
      commit('setHourlyRate', rate);
      dispatch('rebuildLineItem');
    },
    updateCustomer({ commit, dispatch }, customer) {
      commit('setSelectedCustomer', customer);
      commit('setHourlyRate', customer.hourlyRate);
      dispatch('rebuildLineItem');
    },
    updateBilledTimeInSeconds({ commit, dispatch }, seconds) {
      commit('setBilledTimeInSeconds', seconds);
      dispatch('rebuildLineItem');
    },
    updateDeliveryDate({ commit, dispatch }, deliveryDate) {
      commit('setLineItemDeliveryDate', deliveryDate);
      dispatch('rebuildLineItem');
    },
    updateSummary({ commit, dispatch }, summary) {
      commit('setLineItemSummary', summary);
      dispatch('rebuildLineItem');
    },
    removeLineItemError({ state, commit }, error) {
      commit('setLineItemErrors', state.lineItemErrors.filter(item => item != error));
    }
  },
  getters: {
    rootIssue: state => [...state.issues].sort((a, b) => a.level - b.level)[0],
    sortedIssues: state => (
      [...state.issues].sort((a, b) => (
        a.level === b.level ?
          ((+b.jiraKey.split('-')[1] < +a.jiraKey.split('-')[1]) ? 1 : -1) :
          (a.level - b.level)
      ))
    ),
    lastResolvedIssue: (state, getters) => {
      const lastResolvedIssue = [...state.issues]
        .filter(i => i.resolvedAt)
        .sort((a, b) => new Date(a.resolvedAt) < new Date(b.resolvedAt) ? 1 : -1)[0]

      return lastResolvedIssue || getters.rootIssue;
    },
    billedTimeSuggestionInSeconds: (state, getters) => {
      let suggestion;

      if (state.lineItemSelectedWorklogs.length === getters.unbilledWorklogs.length) { // no worklogs have been deselected
        suggestion = state.issues.reduce(
          (sum, i) => (sum += i.billableTimeSuggestionInSeconds, sum),
          0
        );
      } else {
        suggestion = getters.timeSpentInSeconds;
      }

      suggestion = roundSecondsToFullQuarters(suggestion);

      return suggestion;
    },
    offerAmountInSeconds(state) { return state.issues.reduce((sum, i) => (sum += i.offerAmountInSeconds, sum), 0) },
    timeSpentInSeconds(state, getters) { return state.lineItemSelectedWorklogs.reduce((sum, w) => (sum += w.timeSpentInSeconds, sum), 0) },
    billedWorklogs: state => state.worklogs.filter(w => w.isBilled),
    unbilledWorklogs: state => state.worklogs.filter(w => !w.isBilled),
    useOffer: (_, getters) => getters.rootIssue?.useOffer(),
    amount: state => calculateLineItemAmount(state.lineItemHourlyRate, state.lineItemBilledTimeInSeconds),
    titleAmountSegment(state, getters) {
      return getters.useOffer ?
        `nach Angebot: ${numberFormatter.format(getters.amount)}` :
        `Aufwand: ${timeHelpers.prettyTime(state.lineItemBilledTimeInSeconds)}`;
    },
    titleDeliveryDateSegment(state) { return `Lieferdatum: ${state.lineItemDeliveryDate}`; },
    title(state, getters) {
      return [getters.jiraKey, state.lineItemSummary, getters.titleAmountSegment, getters.titleDeliveryDateSegment].join(' | ');
    },
    availableHourlyRates(state, getters) {
      if (getters.useServiceContract) {
        return state.lineItemServiceContract.hourlyRate;
      } else {
        return AVAILABLE_HOURLY_RATES;
      }
    },
    availableServiceContracts(state) { return state.lineItemSelectedCustomer.serviceContracts || []},
    jiraKey(_, getters) { return getters.rootIssue.jiraKey; },
    unbilledTimeInSeconds(_, getters) { return getters.unbilledWorklogs.reduce((sum, w) => (sum += w.timeSpentInSeconds, sum), 0); },
    useServiceContract(state) {
      if (!state.lineItemServiceContract || state.lineItemSelectedCustomer.isMarkedForDisassociation) {
        return false;
      } else {
        return true;
      }
    },
    // hourlyRateSuggestion() => hourly rate of selected customers,
  }
}
