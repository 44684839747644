import { attr, hasOne, hasMany } from 'spraypaint/dist/spraypaint';
import ApplicationRecord from '@/models/ApplicationRecord';

const JiraUser = ApplicationRecord.extend({
  static: {
    jsonapiType: 'jira_users',
  },
  attrs: {
    accountId: attr({ persist: false }),
    displayName: attr({ persist: false }),
    email: attr({ persist: false }),
    active: attr({ persist: false }),

    user: hasOne(),
    worklogs: hasMany()
  }
});

export default JiraUser
