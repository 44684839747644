<template>
  <div :class="active ? 'h-5/6' : 'max-h-80'"
        class="flex flex-col pt-8 mb-5">
    <slot></slot>
  </div>
</template>

<script>
export default {
  name: 'PrimaryContainer',
  props: {
    active: {
      type: Boolean,
      default: true
    }
  }
}
</script>
