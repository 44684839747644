function prettyTime(seconds) {
  if (seconds === null || seconds === undefined) return '-';

  const hours = hoursFromSeconds(seconds);
  return `${hours ? `${hours}h` : ''} ${minutesFromSeconds(seconds)}m`;
}
function hoursFromSeconds(seconds) {
  return Math.floor(seconds / 3600);
}
function minutesFromSeconds(seconds) {
  return seconds / 60 % 60;
}

export default {
  prettyTime,
  hoursFromSeconds,
  minutesFromSeconds
}
