import { attr, belongsTo } from 'spraypaint/dist/spraypaint';
import ApplicationRecord from '@/models/ApplicationRecord';

const Worklog = ApplicationRecord.extend({
  static: {
    jsonapiType: 'worklogs',
  },
  attrs: {
    issueId: attr({ persist: false }),

    jiraId: attr({ persist: false }),
    jiraKey: attr({ persist: false }),
    comment: attr({ persist: false }),
    timeSpentInSeconds: attr({ persist: false }),
    billedTimeInSeconds: attr({ persist: false }),
    isBilled: attr({ persist: false }),

    startedAt: attr({ persist: false }),

    jiraUser: belongsTo(),
    user: belongsTo(),
    lineItem: belongsTo(),
    issue: belongsTo()
  },
  methods: {
    prettyStartedAt() {
      return (new Date(this.startedAt)).toLocaleString('de-de');
    }
  }
});

export default Worklog
