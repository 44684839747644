import { attr, hasMany, belongsTo } from 'spraypaint/dist/spraypaint';
import ApplicationRecord from '@/models/ApplicationRecord';

const LineItem = ApplicationRecord.extend({
  static: {
    jsonapiType: 'line_items',
  },
  attrs: {
    customerId: attr({ persist: false }),
    creatorId: attr({ persist: false }),
    bookerId: attr({ persist: false }),
    invoiceId: attr({ persist: false }),
    serviceContractId: attr({ persist: false }),

    timeSpentInSeconds: attr({ persist: false }),
    billedTimeInSeconds: attr(),
    hourlyRate: attr(),
    amount: attr(),
    title: attr(),

    titleSummary: attr(),
    deliveryDate: attr(),

    description: attr(),
    internalNote: attr(),
    billedAmountExplanation: attr(),

    bookedAt: attr(),
    createdAt: attr({ persist: false }),

    serviceContract: belongsTo(),
    invoice: belongsTo(),
    customer: belongsTo(),
    creator: belongsTo(),
    booker: belongsTo(),
    worklogs: hasMany()
  },
  methods: {
    prettyAmount() {
      return new Intl.NumberFormat('de-DE', { style: 'currency', currency: 'EUR' }).format(this.amount);
    },
    prettyDeliveryDate() {
      if (this.deliveryDate) {
        return (new Date(this.deliveryDate)).toLocaleDateString('de-de');
      } else {
        return (new Date()).toLocaleDateString('de-de');
      }
    }
  }
});

export default LineItem
