<template>
  <div>
    <div class="pb-3 pt-8">
      <h2 class="text-base font-semibold">Mitwirkende ({{ Object.keys(userStats).length }})</h2>
    </div>
    <div class="border-t border-b border-gray-300 bg-gray-50 overflow-scroll">
      <table class="border-collapse w-full table-auto whitespace-nowrap">
        <thead>
          <tr class="divide-x divide-gray-300 leading-7">
            <th class="sticky top-0 bg-gray-200 text-gray-500 font-medium p-1 text-left">Benutzer</th>
            <th class="sticky top-0 bg-gray-200 text-gray-500 font-medium p-1 text-left">enthaltener Aufwand</th>
            <th class="sticky top-0 bg-gray-200 text-gray-500 font-medium p-1 text-left">%</th>
          </tr>
        </thead>
        <tbody>
          <template v-for="(timeSpentInSeconds, userName, idx) in userStats">
            <tr :key="userName"
                class="hover:bg-gray-200 text-gray-600 hover:text-gray-800 cursor-pointer"
                :class="[idx % 2 === 1 ? 'bg-gray-100 text-gray-800' : '']"
                @click="activeUserName = activeUserName == userName ? '' : userName">

              <td class="p-1">
                {{ userName }}
              </td>
              <td class="p-1">
                {{ prettyTime(timeSpentInSeconds) }}
              </td>
              <td class="p-1">
                {{ Math.round(timeSpentInSeconds / issuesTimeSpent * 100) }}
              </td>
            </tr>
            <tr v-if="userName == activeUserName"
                :key="userName + '_worklogs'">
              <td colspan="3">
                <Worklogs :worklogs="availableWorklogs"
                          :username="userName"
                          :hideUser="true"
                          :selectable="activeProject.enableManualWorklogSelection" />
              </td>
            </tr>
          </template>
        </tbody>
      </table>
    </div>
  </div>
</template>

<script>
import timeHelpers from '@/lib/time_helpers';
import Worklogs from '@/components/Worklogs';

export default {
  components: {
    Worklogs
  },
  props: ['worklogs', 'issuesTimeSpent', 'activeProject'],
  methods: {
    prettyTime: timeHelpers.prettyTime
  },
  data() {
    return {
      activeUserName: ''
    };
  },
  computed: {
    availableWorklogs() { return this.$store.getters['lineItem/unbilledWorklogs']; },
    userStats() {
      // TODO: sorting of this is not stable
      const worklogs = this.worklogs;
      let users;

      if (worklogs.length) {
        users = worklogs.reduce((u, w) => {
          const userName = w.jiraUser.displayName;
          u[userName] = u[userName] || 0;
          u[userName] += w.timeSpentInSeconds;
          return u;
        }, {});
      } else {
        users = {};
      }

      return users;
    }
  }
}
</script>
