<template>
  <div>
    <div class="px-4 py-8 sm:px-0">
      <h1 class="text-3xl font-bold leading-tight tracking-tight text-gray-900">Dashboard</h1>
      <div class="px-4 py-8 sm:px-0">
        <div class="h-96 rounded-lg border-4 border-dashed border-gray-200"></div>
      </div>
    </div>
  </div>
</template>

<style>
</style>

<script>
export default {
  name: 'Home'
}
</script>
