import Vue from 'vue';
import VueRouter from 'vue-router';
import store from '@/store';
import Home from '@/views/Home.vue';
import LineItems from '@/views/LineItems.vue';
import LineItemsReport from '@/views/LineItemsReport.vue';
import IssuesOpen from '@/views/IssuesOpen.vue';
import IssuesDone from '@/views/IssuesDone.vue';
import Invoices from '@/views/Invoices.vue';

Vue.use(VueRouter);

const routes = [
  {
    path: '/',
    name: 'Home',
    component: Home
  },
  {
    path: '/lines',
    name: 'lineItems',
    component: LineItems
  },
  {
    path: '/lines/report',
    name: 'LineItemsReport',
    component: LineItemsReport
  },
  {
    path: '/issues/done',
    name: 'closedIssues',
    component: IssuesDone
  },
  {
    path: '/invoices',
    name: 'Invoices',
    component: Invoices
  },
  {
    path: '/issues/open',
    name: 'openIssues',
    component: IssuesOpen
  },
  // {
  //   path: '/about',
  //   name: 'About',
  //   // route level code-splitting
  //   // this generates a separate chunk (about.[hash].js) for this route
  //   // which is lazy-loaded when the route is visited.
  //   component: () => import(/* webpackChunkName: "about" */ '../views/About.vue')
  // }
  {
    path: '/login',
    name: 'login',
    component: () => import(/* webpackChunkName: "login" */ '../views/Login.vue'),
    meta: { noAuth: true }
  },
  {
    path: '*',
    name: 'notFound',
    beforeEnter: (to, from, next) => next('/')
  }
];

const router = new VueRouter({
  mode: 'history',
  base: process.env.BASE_URL,
  routes
});

// Fetch currentUser
router.beforeEach(async (to, from, next) => {
  if (store.getters.hasToken) {
    try {
      store.getters.currentUser || await store.dispatch('fetchCurrentUser');
    } catch (err) {
      store.commit('dropToken');
      next('/login');
    }
  }

  next();
});

// Authentication redirects
router.beforeEach((to, from, next) => {
  if (to.matched.length) {
    if (store.getters.isAuthenticated) {
      if (to.matched[0].meta.noAuth) {
        return next({ path: '/' });
        // router.app.$toast.open({
        //   message: router.app.$t('errors.already_logged_in'),
        //   type: 'is-warning'
        // });
      } else if (to.matched.filter(m => m.meta.adminOnly).length && !store.getters.currentUser.isAdmin) {
        return next({ path: '/' });
        // router.app.$toast.open({
        //   message: router.app.$t('errors.unauthorized'),
        //   type: 'is-danger'
        // });
      }
    } else {
      if (!to.matched[0].meta.noAuth) {
        return next({
          path: '/login',
          query: { t: to.fullPath }
        });
        // router.app.$toast.open({
        //   message: router.app.$t('errors.login_required'),
        //   type: 'is-danger'
        // });
      }
    }
  }

  return next();
});

export default router
