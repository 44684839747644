<template>
  <AppContainer>
    <PrimaryContainer :active="Boolean(!activeLineItem.id)">
      <div class="pb-3">
        <SectionHeading>Neue Rechnungspositionen</SectionHeading>
      </div>
      <div v-if="!customers.length">
        <span class="py-1">keine offenen Rechnungsposition</span>
      </div>
      <ScrollContainer v-else>
        <table class="bg-gray-50 border-collapse w-full table-auto whitespace-nowrap">
          <thead>
            <tr class="divide-x divide-gray-300 leading-7">
              <th class="sticky top-0 bg-gray-200 text-gray-500 font-medium p-1 text-left w-20">Nr.</th>
              <th class="sticky top-0 bg-gray-200 text-gray-500 font-medium p-1 text-left">Kunde</th>
            </tr>
          </thead>
          <tbody>
            <template v-for="(customer, idx) in customers">
              <tr :key="customer.id"
                  class="hover:bg-gray-200 text-gray-600 hover:text-gray-800 cursor-pointer"
                  :class="[idx % 2 === 1 ? 'bg-gray-100 text-gray-800' : '']"
                  @click="fetchLineItemsForCustomer(customer)">

                <td class="p-1">
                  {{ customer.number }}
                </td>
                <td class="p-1">
                  {{ customer.name }}
                </td>
              </tr>
              <tr v-if="customer === activeCustomer"
                  :key="customer.id + '_lineItems'">
                <td colspan="2" class="py-0.5 px-1">
                  <LineItemsByInvoice :lineItems="lineItemsForCustomer"
                                      :lineItemsByInvoice="lineItemsByInvoice"
                                      :activeLineItem="activeLineItem"
                                      @line-item-selected="activateLineItem($event)" />
                </td>
              </tr>
            </template>
          </tbody>
        </table>
      </ScrollContainer>
    </PrimaryContainer>
    <SecondaryContainer :active="Boolean(activeLineItem.id)">
      <div class="pb-4 flex items-center">
        <SectionHeading>Rechnungsposition buchen</SectionHeading>
        <template v-if="activeLineItem.id">

          <MainActionButton @click="saveLineItem">
            buchen
          </MainActionButton>

          <MainActionButton @click="deleteLineItem">
            löschen
          </MainActionButton>

          <SecondaryActionButton @click="deactivateLineItem">
            abbrechen
          </SecondaryActionButton>

        </template>
      </div>
      <div v-if="successNote">
        <span class="text-green-500 py-1">{{ successNote }}</span>
      </div>
      <div v-if="!activeCustomer.id">
        <span class="py-1">Kunden zur Abrechnung auswählen</span>
      </div>
      <div v-else-if="!activeLineItem.id">
        <span class="py-1">Rechnungsposition zur Abrechnung auswählen</span>
      </div>
      <ScrollContainer v-else class="text-gray-600">

        <LineItemForm :editable="currentUser.isBackoffice()" />

      </ScrollContainer>
    </SecondaryContainer>
  </AppContainer>
</template>

<style>
</style>

<script>
import AppContainer from '@/components/AppContainer';
import SectionHeading from '@/components/SectionHeading';
import ScrollContainer from '@/components/ScrollContainer';
import LineItemsByInvoice from '@/components/LineItemsByInvoice';
import MainActionButton from '@/components/MainActionButton';
import SecondaryActionButton from '@/components/SecondaryActionButton';
import LineItemForm from '@/components/LineItemForm';
import PrimaryContainer from '@/components/PrimaryContainer';
import SecondaryContainer from '@/components/SecondaryContainer';

import Customer from '@/models/Customer';
import LineItem from '@/models/LineItem';
import Worklog from '@/models/Worklog';
import Issue from '@/models/Issue'; // sideloaded w/ worklogs
import JiraUser from '@/models/JiraUser'; // sideloaded w/ worklogs

export default {
  name: 'LineItems',
  components: {
    AppContainer,
    SectionHeading,
    ScrollContainer,
    MainActionButton,
    SecondaryActionButton,
    LineItemForm,
    LineItemsByInvoice,
    PrimaryContainer,
    SecondaryContainer
  },
  data() {
    return {
      customers: [],
      activeProject: {},
      activeCustomer: {},
      lineItemsForCustomer: [],
      successNote: ''
    };
  },
  async created() {
    this.$store.dispatch('lineItem/resetLineItem');
    this.fetchCustomers();
  },
  computed: {
    activeLineItem() { return this.$store.state.lineItem.lineItem; },
    currentUser() { return this.$store.getters.currentUser; },
    lineItemsByInvoice() {
      return this.lineItemsForCustomer.reduce((obj, line) => {
        obj[line.invoiceId] ||= [];
        obj[line.invoiceId].push(line);

        return obj
      }, {});
    }
  },
  methods: {
    async fetchCustomers() {
      const customers = await Customer
        .includes(['serviceContracts'])
        .where({ requiresInvoice: false }) // only customers that have lines w/ invoices
        .where({ unbooked: true }) // and lines are not booked
        .order('name')
        .all();
      this.customers = customers.data;
    },
    async fetchLineItemsForCustomer(activeCustomer, { refresh } = {}) {
      this.deactivateLineItem();

      if (!refresh && this.activeCustomer.id === activeCustomer.id) {
        this.deactivateCustomer();
      } else {
        this.lineItemsForCustomer = (await LineItem
          .includes(['serviceContract', 'invoice'])
          .where({ customer_id: activeCustomer.id })
          .where({ requiresInvoice: false }) // only lines w/ invoices
          .where({ booked: false }) // that are not booked
          .all()).data;

        this.activeCustomer = activeCustomer;
      }
    },
    async activateLineItem(lineItem) {
      this.successNote = '';

      if (lineItem.id === this.activeLineItem.id) {
        this.deactivateLineItem();
      } else {
        // worklogs for lineItem only!
        // we need worklogs of all issues to display all stats
        const worklogs = (await Worklog
          .includes(['jiraUser', { issue: 'project' }])
          .where({ line_item_id: lineItem.id })
          .order('started_at')
          .all()
        ).data;

        this.activeProject = worklogs[0].issue.project;

        const customers = (await Customer
          .includes(['serviceContracts'])
          .where({ id: this.activeProject.customerIds })
          .all()
        ).data;

        this.$store.dispatch(
          'lineItem/loadLineItem',
          { lineItem, customers, worklogs, selectedCustomer: this.activeCustomer });
      }
    },
    deactivateCustomer() {
      this.activeCustomer = {};
    },
    deactivateLineItem() {
      this.$store.dispatch('lineItem/resetLineItem');

      this.activeProject = {};
    },
    async deleteLineItem() {
      // move into store
      await this.activeLineItem.destroy();
      await this.updateLineItems();
      this.successNote = 'Rechnungsposition wurde gelöscht!';
    },
    async saveLineItem() {
      if (await this.$store.dispatch('lineItem/bookLineItem')) {
        await this.updateLineItems();
        this.successNote = 'Rechnungsposition wurde erfolgreich gebucht!';
      }
    },
    async updateLineItems() {
      this.deactivateLineItem();
      await this.fetchLineItemsForCustomer(this.activeCustomer, { refresh: true } );
      if (!this.lineItemsForCustomer.length) {
        this.deactivateCustomer();
        this.fetchCustomers();
      }
    }
  }
}
</script>
