import { attr, hasMany } from 'spraypaint/dist/spraypaint';
import ApplicationRecord from '@/models/ApplicationRecord';

const Customer = ApplicationRecord.extend({
  static: {
    jsonapiType: 'customers',
  },
  attrs: {
    number: attr({ persist: false }),
    name: attr({ persist: false }),
    hourlyRate: attr({ persist: false }),

    projects: hasMany(),
    serviceContracts: hasMany()
  },
  methods: {
    displayTitle() { return `${this.number} · ${this.name}`; }
  }
});

export default Customer
