<template>
  <div>
    <div class="pb-3 pt-8">
      <h2 class="text-base font-semibold">Enthaltene Vorgänge ({{ issues.length }})</h2>
    </div>
    <div class="border-t border-b border-gray-300 bg-gray-50 overflow-scroll">
      <table class="border-collapse w-full table-auto whitespace-nowrap">
        <thead>
          <tr class="divide-x divide-gray-300 leading-7">
            <th class="sticky top-0 bg-gray-200 text-gray-500 font-medium p-1 text-left">ID</th>
            <th class="sticky top-0 bg-gray-200 text-gray-500 font-medium p-1 text-left">Abrechnung</th>
            <th class="sticky top-0 bg-gray-200 text-gray-500 font-medium p-1 text-left">offen</th>
            <th class="sticky top-0 bg-gray-200 text-gray-500 font-medium p-1 text-left">abgerechnet</th>
            <th class="sticky top-0 bg-gray-200 text-gray-500 font-medium p-1 text-left">angefallen</th>
            <th class="sticky top-0 bg-gray-200 text-gray-500 font-medium p-1 text-left">angeboten</th>
            <th class="sticky top-0 bg-gray-200 text-gray-500 font-medium p-1 text-left">geschätzt</th>
            <th class="sticky top-0 bg-gray-200 text-gray-500 font-medium p-1 text-left">Restaufwand</th>
            <th class="sticky top-0 bg-gray-200 text-gray-500 font-medium p-1 text-left">fertiggestellt</th>
          </tr>
        </thead>
        <tbody>
          <template v-for="(issue, idx) in issues">
            <tr :key="issue.id + 'data'"
                class="text-gray-600 hover:text-gray-800"
                :class="[idx % 2 === 1 ? 'bg-gray-100 text-gray-800' : '']">

              <td class="p-1">
                  <IssueLink :jiraKey="issue.jiraKey" />
              </td>
              <td class="p-1">
                {{ issue.billableType }}
              </td>
              <td class="p-1">
                {{ prettyTime(issue.openTimeInSeconds) }}
              </td>
              <td class="p-1">
                ~ {{ prettyTime(issue.billedTimeInSeconds) }}
              </td>
              <td class="p-1">
                {{ prettyTime(issue.timeSpentInSeconds) }}
              </td>
              <td class="p-1">
                {{ prettyTime(issue.offerAmountInSeconds) }}
              </td>
              <td class="p-1">
                {{ prettyTime(issue.originalEstimateInSeconds) }}
              </td>
              <td class="p-1">
                {{ prettyTime(issue.remainingEstimateInSeconds) }}
              </td>
              <td class="p-1">
                {{ issue.prettyResolvedAt() || '-' }}
              </td>
            </tr>
          </template>
        </tbody>
        <tfoot>
          <tr class="text-gray-600 hover:text-gray-800 border-2 font-bold">
            <td class="p-1">
              &nbsp;
            </td>
            <td class="p-1">
              &nbsp;
            </td>
            <td class="p-1">
              {{ prettyTime(issues.reduce((sum, issue) => (sum += issue.openTimeInSeconds, sum), 0)) }}
            </td>
            <td class="p-1">
              ~ {{ prettyTime(issues.reduce((sum, issue) => (sum += issue.billedTimeInSeconds, sum), 0)) }}
            </td>
            <td class="p-1">
              {{ prettyTime(issues.reduce((sum, issue) => (sum += issue.timeSpentInSeconds, sum), 0)) }}
            </td>
            <td class="p-1">
              {{ prettyTime(issues.reduce((sum, issue) => (sum += issue.offerAmountInSeconds, sum), 0)) }}
            </td>
            <td class="p-1">
              {{ prettyTime(issues.reduce((sum, issue) => (sum += issue.originalEstimateInSeconds, sum), 0)) }}
            </td>
            <td class="p-1">
              {{ prettyTime(issues.reduce((sum, issue) => (sum += issue.remainingEstimateInSeconds, sum), 0)) }}
            </td>
            <td class="p-1">
              &nbsp;
            </td>
          </tr>
        </tfoot>
      </table>
    </div>
  </div>
</template>

<script>
import IssueLink from '@/components/IssueLink';

import timeHelpers from '@/lib/time_helpers';

export default {
  components: {
    IssueLink
  },
  props: ['issues'],
  methods: {
    prettyTime: timeHelpers.prettyTime
  }
}
</script>
