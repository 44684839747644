<template>
  <div class="text-center">
    <div class="mt-2 flex space-x-3 items-center">
      <div class="flex w-full">
        <input type="number"
             min="0"
             max="999"
             :value="billableHours"
             @input="calcSecondsAndUpdate('billableHours', $event.target.value)"
             class="block w-full rounded-none rounded-l-md border border border-neutral-300 px-3 py-2"
             :class="[!lineItemErrors.includes('billedTimeInSeconds') ? 'bg-white' : 'bg-red-200']"
             @blur="$store.dispatch('lineItem/validateLineItem')">
        <span class="w-9 flex-shrink rounded-r-md border border-l-0 border-neutral-300 bg-neutral-100 text-neutral-500 block w-full px-3 py-2">h</span>
      </div>

      <div class="flex w-full">
             <input type="number"
             min="-15"
             max="60"
             step="15"
             :value="billableMinutes"
             @input="calcSecondsAndUpdate('billableMinutes', $event.target.value)"
             class="block w-full rounded-none rounded-l-md border border border-neutral-300 px-3 py-2"
             :class="[!lineItemErrors.includes('billedTimeInSeconds') ? 'bg-white' : 'bg-red-200']"
             @blur="$store.dispatch('lineItem/validateLineItem')">
        <span class="w-9 flex-shrink rounded-r-md border border-l-0 border-neutral-300 bg-neutral-100 text-neutral-500 block w-full px-3 py-2">m</span>
      </div>
    </div>
    <SecondaryActionButton class="text-orange-400 hover:text-orange-600 flex mt-2 items-center"
                          v-if="useOfferAmountBtn"
                          @click.native="updateAmount(offerAmountInSeconds)">
      Angebotenen Aufwand verwenden
    </SecondaryActionButton>
    <SecondaryActionButton class="text-orange-400 hover:text-orange-600 flex mt-2 items-center"
                          v-else-if="useSpentAmountBtn"
                          @click.native="updateAmount(timeSpentInSeconds, true)">
      Angefallenen Aufwand verwenden
    </SecondaryActionButton>
  </div>
</template>

<script>
import SecondaryActionButton from '@/components/SecondaryActionButton';
import timeHelpers from '@/lib/time_helpers';

export default {
  components: {
    SecondaryActionButton
  },
  computed: {
    timeSpentInSeconds() {
      return this.$store.getters['lineItem/timeSpentInSeconds'];
    },
    offerAmountInSeconds() {
      return this.$store.getters['lineItem/offerAmountInSeconds'];
    },
    billedTimeInSeconds() {
      return this.$store.state.lineItem.lineItemBilledTimeInSeconds;
    },
    billableHours() {
      return timeHelpers.hoursFromSeconds(this.billedTimeInSeconds);
    },
    billableMinutes() {
      return timeHelpers.minutesFromSeconds(this.billedTimeInSeconds);
    },
    useSpentAmountBtn() {
      return this.timeSpentInSeconds > this.billedTimeInSeconds;
    },
    useOfferAmountBtn() {
      return this.offerAmountInSeconds &&
        this.offerAmountInSeconds > this.billedTimeInSeconds;
    },
    lineItemErrors() {
      return this.$store.state.lineItem.lineItemErrors;
    }
  },
  methods: {
    calcSecondsAndUpdate(key, val) {
      let billableHours = 0;
      let billableMinutes = 0;

      if (key === 'billableHours') {
        billableHours = val;
        billableMinutes = this.billableMinutes;
      } else {
        billableHours = this.billableHours;
        billableMinutes = val;
      }

      const billedTimeInSeconds = billableHours * 3600 + billableMinutes * 60;

      this.updateAmount(billedTimeInSeconds);
    },
    updateAmount(seconds, roundToFullQuarters = false) {
      if (roundToFullQuarters) seconds = Math.ceil(seconds / 60 / 15) * 60 * 15;

      this.$store.dispatch('lineItem/updateBilledTimeInSeconds', seconds);
    }
  }
}
</script>
