<template>
  <table class="border-collapse w-full table-auto border border-gray-300">
    <tbody>
      <template v-for="(lineItems, invoiceId, idx) in lineItemsByInvoice">
        <tr class="text-gray-800 bg-gray-300"
            :key="invoiceId">
          <td class="p-1 border-t-2 border-b-2 border-gray-400" colspan="2">
            Rechnung #{{ idx + 1 }}
            <span v-if="lineItems[0].invoice && lineItems[0].invoice.internalNote">
              - {{ lineItems[0].invoice.internalNote }}
            </span>
          </td>
        </tr>
        <template v-for="(lineItem, idx) in lineItems">
          <tr class="text-gray-800"
              :class="[idx % 2 === 0 ? 'bg-gray-200' : 'bg-gray-100' ]"
              :key="lineItem.id">
            <td class="p-1">
              <input type="checkbox"
                    class="relative top-0.5"
                    :checked="checkLineItemActiveState(lineItem)"
                    @change="$emit('line-item-selected', lineItem)" />
            </td>
            <td class="p-1">
              {{ lineItem.title }}
            </td>
          </tr>
        </template>
      </template>
    </tbody>
  </table>
</template>

<script>
export default {
  name: 'LineItemsByInvoice',
  props: {
    activeLineItem: {
      type: Object,
      required: true
    },
    lineItems: {
      type: Array,
      required: true
    },
    lineItemsByInvoice: {
      type: Object,
      required: true
    }
  },
  methods: {
    checkLineItemActiveState(lineItem) {
      return this.activeLineItem.id === lineItem.id;
    }
  }
}
</script>
