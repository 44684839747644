<template>
<select v-model="hourlyRate"
        class="mt-2 px-3 w-full py-2 bg-white border border-neutral-300 rounded"
        :disabled="!hourlyRates.length">
  <option v-for="rate in hourlyRates"
          :value="rate"
          :key="rate">
    {{ rate }}
  </option>
</select>
</template>

<script>
export default {
  computed: {
    hourlyRate: {
      get() { return this.$store.state.lineItem.lineItemHourlyRate; },
      set(hourlyRate) { this.$store.dispatch('lineItem/updateHourlyRate', hourlyRate); }
    },
    hourlyRates() { return this.$store.getters['lineItem/availableHourlyRates']; }
  }
}
</script>
