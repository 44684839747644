import { attr, belongsTo, hasMany } from 'spraypaint/dist/spraypaint';
import ApplicationRecord from '@/models/ApplicationRecord';

const ServiceContract = ApplicationRecord.extend({
  static: {
    jsonapiType: 'service_contracts',
  },
  attrs: {
    name: attr({ persist: false }),
    hourlyRate: attr({ persist: false }),
    monthlyHours: attr({ persist: false }),

    customer: belongsTo()
  }
});

export default ServiceContract
