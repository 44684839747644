<template>
  <div class="mt-2 flex items-baseline flex-row space-x-3">
    <div class="flex w-full">
      <div class="w-36 text-right flex-shrink rounded-l-md border border-r-0 border-neutral-300 bg-neutral-100 text-neutral-500 block w-full px-3 py-2">{{ jiraKey }}</div>
      <input v-model="summary"
             class="block w-full rounded-none rounded-r-md border border border-neutral-300 px-3 py-2"
             :class="[!lineItemErrors.includes('titleSummary') ? 'bg-white' : 'bg-red-200']"
             @blur="$store.dispatch('lineItem/validateLineItem')">
    </div>
    <div class="flex-none">{{ amountSegment }}</div>
    <span>|</span>
    <div class="flex-none">Lieferdatum:</div>
    <div class="flex-shrink relative">
      <div class="pointer-events-none absolute inset-y-0 left-0 flex items-center pl-3">
        <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24" fill="currentColor" class="h-5 w-5">
          <path d="M12.75 12.75a.75.75 0 11-1.5 0 .75.75 0 011.5 0zM7.5 15.75a.75.75 0 100-1.5.75.75 0 000 1.5zM8.25 17.25a.75.75 0 11-1.5 0 .75.75 0 011.5 0zM9.75 15.75a.75.75 0 100-1.5.75.75 0 000 1.5zM10.5 17.25a.75.75 0 11-1.5 0 .75.75 0 011.5 0zM12 15.75a.75.75 0 100-1.5.75.75 0 000 1.5zM12.75 17.25a.75.75 0 11-1.5 0 .75.75 0 011.5 0zM14.25 15.75a.75.75 0 100-1.5.75.75 0 000 1.5zM15 17.25a.75.75 0 11-1.5 0 .75.75 0 011.5 0zM16.5 15.75a.75.75 0 100-1.5.75.75 0 000 1.5zM15 12.75a.75.75 0 11-1.5 0 .75.75 0 011.5 0zM16.5 13.5a.75.75 0 100-1.5.75.75 0 000 1.5z" />
          <path fill-rule="evenodd" d="M6.75 2.25A.75.75 0 017.5 3v1.5h9V3A.75.75 0 0118 3v1.5h.75a3 3 0 013 3v11.25a3 3 0 01-3 3H5.25a3 3 0 01-3-3V7.5a3 3 0 013-3H6V3a.75.75 0 01.75-.75zm13.5 9a1.5 1.5 0 00-1.5-1.5H5.25a1.5 1.5 0 00-1.5 1.5v7.5a1.5 1.5 0 001.5 1.5h13.5a1.5 1.5 0 001.5-1.5v-7.5z" clip-rule="evenodd" />
        </svg>
      </div>
      <input v-model="deliveryDate"
             class="px-3 w-32 pl-10 py-2 border border-neutral-300 rounded"
             :class="[!lineItemErrors.includes('deliveryDate') ? 'bg-white' : 'bg-red-200']"
             @blur="$store.dispatch('lineItem/validateLineItem')">
    </div>
  </div>

</template>

<script>
export default {
  computed: {
    deliveryDate: {
      get() { return this.$store.state.lineItem.lineItemDeliveryDate; },
      set(deliveryDate) { this.$store.dispatch('lineItem/updateDeliveryDate', deliveryDate); }
    },
    summary: {
      get() { return this.$store.state.lineItem.lineItemSummary; },
      set(summary) { this.$store.dispatch('lineItem/updateSummary', summary); }
    },
    jiraKey() { return this.$store.getters['lineItem/jiraKey']; },
    amountSegment() { return this.$store.getters['lineItem/titleAmountSegment']; },
    lineItemErrors() {
      return this.$store.state.lineItem.lineItemErrors;
    }
  }
}
</script>
