<template>
  <table class="border-collapse w-full table-auto border border-gray-300">
    <tbody>
      <template v-for="(lineItem, idx) in lineItems">
        <tr class="text-gray-800"
            :class="[idx % 2 === 0 ? 'bg-gray-200' : 'bg-gray-100' ]"
            :key="lineItem.id">
          <td class="p-1">
            <input type="checkbox"
                   class="relative top-0.5"
                   :checked="checkLineItemActiveState(lineItem)"
                   @change="$emit('line-item-selected', lineItem)" />
          </td>
          <td class="p-1">
            {{ lineItem.title }}
          </td>
        </tr>
      </template>
    </tbody>
  </table>
</template>

<script>
export default {
  name: 'LineItemDetails',
  props: {
    activeLineItems: {
      type: Array,
      required: true
    },
    lineItems: {
      type: Array,
      required: true
    }
  },
  methods: {
    checkLineItemActiveState(lineItem) {
      return this.activeLineItems.includes(lineItem);
    }
  }
}
</script>
