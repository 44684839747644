<template>
  <button type="button"
          @click="$emit('click')"
          :disabled="disabled"
          class="text-xs uppercase px-5 py-3 rounded-md font-bold text-white"
          :class="[!disabled ? 'hover:text-yellow-300 bg-black' : 'bg-neutral-400']">
    <slot></slot>
  </button>
</template>

<script>
export default {
  props: {
    disabled: {
      type: Boolean,
      default: false
    }
  }
}
</script>
